import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { WithdrawalWay, PaginationRes } from '@golden/gdk-admin'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import CoreTable from '../../default/present/CoreTable'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useGetDataByPayload, useDialogHandleClick, useReload, useRequestFromSearch, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createTableData, createDefaultPaginationData, formatMoney } from '../../../utils/default/TableHelper'
import TableStatus from '../../default/present/TableStatus'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import FinanceWithdrawalWayButtons from './FinanceWithdrawalWayButtons'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { searchToRequest, RequestType } from '../../../views/admin/finance/FinanceWithdrawalWayPage'
import allRoute from '../../../components/admin/route/route'
import WithdrawWayTypeName from '../../../constants/admin/withdrawWayTypeName'
import Switch from '../Switch'
import { isUndefined, omitBy } from '@golden/utils'

interface RowTyep {
  id: number
  forwarder: string
  courier: React.ReactElement
  method: string
  auto: string
  autoMin: string
  autoMax: string
  withdrawCash: string
  min: string
  max: string
  ranks: React.ReactElement
  status: React.ReactElement
  functions: React.ReactElement
  autoWithdraw: React.ReactElement
}

const AutoWithdrawSwitch: React.FC<{
  item: WithdrawalWay
  reload: () => void
  disabled?: boolean
}> = React.memo((props) => {
  const { item, reload, disabled = false } = props
  const globalDialog = useGlobalDialog()
  const classes = useCommonStyles()
  const gdk = useGDK()
  const { t } = useT()

  const payload = useMemo(() => ({
    forwarder_id: item.forwarder_id,
    cash_auto_min: Number(item.cash_auto_min).toString(),
    cash_auto_max: Number(item.cash_auto_max).toString(),
    cash_min: Number(item.cash_min).toString(),
    cash_max: Number(item.cash_max).toString(),
    layer_ids: item.layers.map((item) => item.id),
    is_active: item.is_active,
    is_auto_withdraw: !item.is_auto_withdraw,
    method: item.method
  }), [item])
  const handleClick = useDialogHandleClick({
    dialogId: `${item.is_auto_withdraw ? 'stop' : 'start'}AutoDepositWay-${item.id}`,
    globalDialog,
    changeDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {item.is_auto_withdraw ? t('dialog.confirmStopAutoWithdrawalWay') : t('dialog.confirmStartAutoWithdrawalWay')}
          <span className={classes.purpleWord}>{item.forwarder_name}</span>
        </React.Fragment>
      )
    }),
    successDialogConfig: createGlobalDialogConfig({
      showIcon: false,
      message: item.is_auto_withdraw ? t('dialog.stopAutoWithdrawalWaySuccess') : t('dialog.startAutoWithdrawalWaySuccess'),
      showCancel: false
    }),
    getFailDialogConfig: (error: string) => createGlobalDialogConfig({
      variant: 'error',
      showIcon: true,
      message: error,
      showCancel: false
    }),
    payload,
    gdkFunc: (payload) => gdk.finance.updateWithdrawalWay(item.id, payload),
    gdkFuncDependencies: [gdk, item.id],
    onSuccess: () => reload(),
    onSuccessDependencies: [reload]
  })

  return (
    <Switch
      value={item.is_auto_withdraw}
      onChange={() => {
        handleClick()
        return false
      }}
      disabled={disabled}
    />
  )
})

const FinanceWithdrawalWayTable: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const commonClasses = useCommonStyles()
  const { reloadFlag, reload } = useReload()
  const pageFlow = usePageFlow(true)
  const gdk = useGDK()
  const writable = useChecker()
  const { t } = useT()

  const [list, setList] = useState<PaginationRes<WithdrawalWay[]>>(createDefaultPaginationData([]))

  const payload = useMemo(() => {
    const converted = { ...request, page: request?.page ?? 1, is_active: request?.is_active === 2 ? undefined : request?.is_active } as RequestType
    return omitBy(converted, isUndefined) as RequestType
  }, [request])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => gdk.finance.getWithdrawalWayList(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<WithdrawalWay[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const tableClasses = useMemo(() => {
    return {
      head: commonClasses.pinkTableHead,
      row: commonClasses.tableRow,
      cellHead: commonClasses.tableCellHead
    }
  }, [commonClasses])

  const rows = useMemo(() => {
    return list.data.map((item) => {
      return {
        id: item.id,
        forwarder: item.slug_name,
        courier: (
          <React.Fragment>
            {
              item.is_auto_withdraw && (
                <p>
                  <span className={commonClasses.chipText} style={{ backgroundColor: '#72d476' }}>
                    {t('common.autoWithdrawal')}
                  </span>
                </p>
              )
            }
            <p>{item.forwarder_name}</p>
          </React.Fragment>
        ),
        method: t(WithdrawWayTypeName[item.method]),
        auto: '',
        autoMin: formatMoney(item.cash_auto_min),
        autoMax: formatMoney(item.cash_auto_max),
        withdrawCash: '',
        min: formatMoney(item.cash_min),
        max: formatMoney(item.cash_max),
        ranks: (
          <React.Fragment>
            { item.layers.map(layer => layer.name).join('、') }
          </React.Fragment>
        ),
        status: (
          <TableStatus status={item.is_active} />
        ),
        functions: (
          <FinanceWithdrawalWayButtons
            item={item}
            reload={reload}
          />
        ),
        autoWithdraw: (
          <AutoWithdrawSwitch
            item={item}
            reload={reload}
            disabled={!writable}
          />
        )
      } as RowTyep
    })
  }, [list.data, commonClasses.chipText, t, reload, writable])
  const data = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        forwarder: {
          label: t('common.forwarder'),
          value: 'forwarder',
          align: 'center'
        },
        courier: {
          label: t('common.courierName'),
          value: 'courier',
          align: 'center'
        },
        method: {
          label: t('common.playerWithdrawProcess'),
          value: 'method',
          align: 'center'
        },
        auto: {
          label: `${t('common.autoWithdrawalMaxMoney')} (RMB)`,
          value: 'autoMax',
          align: 'center',
          groupItems: ['autoMin', 'autoMax']
        },
        autoMin: {
          label: t('common.min'),
          value: 'autoMin',
          columnAlign: 'center',
          align: 'right'
        },
        autoMax: {
          label: t('common.max'),
          value: 'autoMax',
          columnAlign: 'center',
          align: 'right'
        },
        withdrawCash: {
          label: `${t('common.withdrawCash')} (RMB)`,
          value: 'withdrawCash',
          align: 'center',
          groupItems: ['min', 'max']
        },
        min: {
          label: t('common.min'),
          value: 'min',
          align: 'center'
        },
        max: {
          label: t('common.max'),
          value: 'max',
          align: 'center'
        },
        ranks: {
          label: t('common.openLayer'),
          value: 'ranks',
          align: 'center',
          width: 120
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        },
        autoWithdraw: {
          label: t('common.autoWithdrawal'),
          value: 'auto',
          align: 'center'
        }
      },
      writable
        ? [
            'forwarder',
            'courier',
            'method',
            'withdrawCash',
            'auto',
            'ranks',
            'status',
            'functions',
            'autoWithdraw'
          ]
        : [
            'forwarder',
            'courier',
            'method',
            'withdrawCash',
            'auto',
            'ranks',
            'status'
          ],
      rows,
      'id'
    )
  }, [t, rows, writable])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.financeWithdrawalWay.encodePath,
    pageFlow
  })

  return (
    <LoadingAndErrorFrame { ...pageFlow.status }>
      <CoreTable
        classes={tableClasses}
        data={data}
        total={list.total}
        showPagination
        page={request?.page ?? 1}
        onChangePage={handlePagination}
        loading={pageFlow.status.loading}
      />
    </LoadingAndErrorFrame>
  )
}

export default FinanceWithdrawalWayTable
