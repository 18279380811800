import React, { useState, useMemo, PropsWithChildren } from 'react'
import {
  GameCategoryType,
  GameStatus,
  GameType,
  PaginationRes,
  PlayerLeaderboardCategoryDetailItem,
  PlayerLeaderboardItem
} from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import { searchToRequest } from './AdminReportPlayerLeaderboardDetailForm'
import CoreTable from '../../default/present/CoreTable'
import PointsCell from '../../default/present/PointsCell'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { ColumnCollection, createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute from '../route/route'
import { Link } from 'react-router-dom'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import SortIcon from '@material-ui/icons/Sort'
import { StoreGame } from '../../../utils/default/PlatformHelper'

interface PropType {
  category: 0 | GameCategoryType
}

type RowType = {
  [key in GameType]: React.ReactElement
} & {
  id: number
  account: React.ReactElement
  revenue: React.ReactElement
}

const SortableLabel: React.FC<PropsWithChildren<{
  disabled?: boolean
  value: boolean
  handleClick: () => void
}>> = React.memo((props) => {
  const { disabled = false, value, handleClick, children } = props
  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="center"
      style={{ cursor: !disabled ? 'pointer' : 'default' }}
      onClick={handleClick}
    >
      {children}
      {!disabled && value && (<SortIcon />)}
    </Box>
  )
})

const AdminReportPlayerLeaderboardTable: React.FC<PropType> = (props) => {
  const { category } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const games = useGDKStore.platform.games()
  const aliveGames = useGDKStore.platform.aliveGames()
  const [gameId, setGameId] = useState<0 | GameType>(0)

  const [res, setRes] = useState<PaginationRes<PlayerLeaderboardCategoryDetailItem[]>>(createDefaultPaginationData([]))
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })
  const payload = useMemo(() => {
    if (!request) return null
    return { ...request, game_category: category }
  }, [category, request])
  useGetDataByPayload({
    payload,
    gdkFunc: (payload) => category === 0
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ? gdk.history.getPlayerLeaderboardDetailAll(payload!)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      : gdk.history.getPlayerLeaderboardDetailCategories(gameId === 0 ? payload! : { ...payload!, sort_by: gameId }),
    gdkFuncDependencies: [gdk, gameId],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<PlayerLeaderboardItem[]> | PaginationRes<PlayerLeaderboardCategoryDetailItem[]>) => {
      setRes({ ...res, data: res.data.map((el) => ({ ...el, games: 'games' in el ? el.games : [] })) })
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: !!payload
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead
  }), [classes])
  const rows = useMemo(() => {
    return res.data
      .map((el) => el.games.reduce((pre, cur) => ({
        ...pre,
        [cur.game_id]: (<PointsCell points={cur.revenue} />)
      }), {
        id: el.id,
        account: (<Link to={allRoute.playerDetail.encodePath({ search: {}, param: { id: el.id } })}>{ el.account }</Link>),
        revenue: (<PointsCell points={el.revenue} />)
      }) as RowType)
  }, [res.data])
  const data = useMemo(() => {
    const basePosition = ['id', 'account', 'revenue'] as const
    const filterRemovedGameColumnWithZeroCB = (el: StoreGame) => (aliveGames.some((game) => (game.id === el.id)) || res.data.some((item) => (!!Number(item.games.find((game) => (game.game_id === el.id))?.revenue))))
    const gameIds = rows.length > 0
      ? Object.keys(rows[0])
        .filter((el) => !([...basePosition] as string[]).includes(el))
        .map((el) => Number(el) as GameType)
      : []
    const columns = games.filter((game) => (gameIds.includes(game.id) && filterRemovedGameColumnWithZeroCB(game)))
      .reduce(
        (pre, cur) => ({
          ...pre,
          [cur.id]: {
            label: (
              <SortableLabel
                value={gameId === cur.id}
                handleClick={() => setGameId(cur.id)}
              >
                {cur.nameWithStatus}
              </SortableLabel>
            ),
            value: cur.id.toString(),
            align: 'center'
          }
        }),
        {
          id: {
            label: '',
            value: 'id'
          },
          account: {
            label: t('common.account'),
            value: 'account',
            align: 'center'
          },
          revenue: {
            label: (
              <SortableLabel
                disabled={category === 0}
                value={gameId === 0}
                handleClick={() => setGameId(0)}
              >
                {t('common.totalWinAndLose')}
              </SortableLabel>
            ),
            value: 'revenue',
            align: 'center'
          }
        }
      ) as ColumnCollection<RowType>
    const position = [...basePosition.filter((el) => el !== 'id'), ...games
      .filter((game) => (gameIds.includes(game.id) && filterRemovedGameColumnWithZeroCB(game)))
      .map((el) => el.id.toString())] as Array<keyof RowType>
    return createTableData<RowType>(columns, position, rows, 'id')
  }, [category, gameId, games, rows, t])

  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: (options) => allRoute.adminReportPlayerLeaderboardDetail.encodePath({ ...options, param: { ...options.param, id: category } }),
    pageFlow
  })

  if (request === undefined) return null
  return (
    <Box paddingBottom={3}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={data}
              total={res.total}
              showPagination
              page={request.page}
              perPage={res.per_page}
              onChangePage={handlePagination}
              loading={pageFlow.status.loading}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </Box>
  )
}

export default React.memo(AdminReportPlayerLeaderboardTable)
