import React, { createContext, useMemo, useCallback, useContext, useState } from 'react'
import { CountryCallingCode, PermissionType, SmsOperatorType, SmsProviderRes, SmsReceiveStatusType, SmsResendWayType, SmsSendStatusType, SmsTriggerType } from '@golden/gdk-admin'
import { getTime, startOfYear, subYears } from 'date-fns'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import { searchToRequest, Request } from '../../../views/admin/forestage/AnnouncementSmsPage'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit, useReload, useGetData } from '../../../utils/default/ComplexFlowHook'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import { convertEmptyToUndefined, getValueFromChangeEvent, getTimeFromDateInputValue } from '../../../utils/default/FormHelper'
import allRoute from '../route/route'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import { createShouldDisableDate } from '../../../utils/default/TimeHelper'
import AnnouncementSmsDialog from './AnnouncementSmsDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import smsTriggerNname from '../../../constants/admin/smsTriggerName'
import smsSendStatusName from '../../../constants/admin/smsSendStatusName'
import smsReceiveStatusName from '../../../constants/admin/smsReceiveStatusName'
import smsOperatorNname from '../../../constants/admin/smsOperatorName'
import AnnouncementSmsResendDialog from './AnnouncementSmsResendDialog'

export interface AnnouncementSmsFormType {
  sendTime: DateInputValue
  verifyTime: DateInputValue
  provider: string | '--'
  phone: string
  account: string
  trigger: SmsTriggerType | '--'
  serial_number: string
  send_status: SmsSendStatusType | '--'
  receive_status: SmsReceiveStatusType | '--'
  operator: SmsOperatorType | '--'
  is_verified: 0 | 1 | '--'
}

const initialForm: InitialFormFunc<AnnouncementSmsFormType> = (defaultForm) => ({
  sendTime: {
    start: null,
    end: null
  },
  verifyTime: {
    start: null,
    end: null
  },
  provider: '--',
  phone: '',
  account: '',
  trigger: '--',
  serial_number: '',
  send_status: '--',
  receive_status: '--',
  operator: '--',
  is_verified: '--',
  ...defaultForm
})

const formToRequest = (form: AnnouncementSmsFormType): Request => {
  const converted: Request = {
    send_start_at: form.sendTime.start === null ? undefined : getTime(form.sendTime.start),
    send_end_at: form.sendTime.end === null ? undefined : getTime(form.sendTime.end),
    verify_start_at: form.verifyTime.start === null ? undefined : getTime(form.verifyTime.start),
    verify_end_at: form.verifyTime.end === null ? undefined : getTime(form.verifyTime.end),
    provider: form.provider === '--' ? undefined : form.provider,
    phone: convertEmptyToUndefined(form.phone),
    account: convertEmptyToUndefined(form.account),
    trigger: form.trigger === '--' ? undefined : form.trigger,
    serial_number: convertEmptyToUndefined(form.serial_number),
    send_status: form.send_status === '--' ? undefined : form.send_status,
    receive_status: form.receive_status === '--' ? undefined : form.receive_status,
    operator: form.operator === '--' ? undefined : form.operator,
    is_verified: form.is_verified === '--' ? undefined : form.is_verified,
    page: 1
  }
  return omitBy(converted, isUndefined) as Request
}

const getValueFromEvent: ValueGetter<AnnouncementSmsFormType> = {
  sendTime: getTimeFromDateInputValue,
  verifyTime: getTimeFromDateInputValue,
  provider: getValueFromChangeEvent,
  phone: getValueFromChangeEvent,
  account: getValueFromChangeEvent,
  trigger: getValueFromChangeEvent,
  serial_number: getValueFromChangeEvent,
  send_status: getValueFromChangeEvent,
  receive_status: getValueFromChangeEvent,
  operator: getValueFromChangeEvent,
  is_verified: getValueFromChangeEvent
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)

const SendDateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.sendTime.start, value.sendTime.end, fiveYearsAgo)
  }, [value.sendTime])
  const tools = useMemo(() => {
    return []
  }, [])
  const startOption = useMemo(() => ({
    label: t('common.sendStartAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.sendEndAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('sendTime'), [])
  return (
    <DateInputBase
      sm
      value={value.sendTime}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const VerifyDateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.verifyTime.start, value.verifyTime.end, fiveYearsAgo)
  }, [value.verifyTime])
  const tools = useMemo(() => {
    return []
  }, [])
  const startOption = useMemo(() => ({
    label: t('common.verifyStartAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.verifyEndAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('verifyTime'), [])
  return (
    <DateInputBase
      sm
      value={value.verifyTime}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
    />
  )
})

const AnnouncementSmsForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const { reload, reloadFlag } = useReload()
  const [open, setOpen] = useState<boolean>(false)
  const [result, setResult] = useState<SmsProviderRes | null>(null)
  useGetData({
    gdkFunc: () => gdk.sms.getSmsProviders(),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res) => {
      setResult(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })
  const providerOptions = useMemo(() => {
    const allProviders = [
      ...(result?.[CountryCallingCode.CN].providers ?? []),
      ...(result?.[CountryCallingCode.HK].providers ?? []),
      ...(result?.[CountryCallingCode.MO].providers ?? []),
      ...(result?.[CountryCallingCode.CAMBODIA].providers ?? []),
      ...(result?.[CountryCallingCode.DUBAI].providers ?? [])
    ].map((item) => ({
      name: item.name,
      value: item.code
    }))
    return [{ name: t('common.all'), value: '--' }].concat(allProviders.filter((item, index) => (allProviders.findIndex((el) => (el.name === item.name)) === index)))
  }, [result, t])
  const request = useRequestFromSearch({ searchToRequest })
  const defaultValue = useMemo(() => {
    if (request !== undefined) {
      return initialForm({
        sendTime: {
          start: request.send_start_at ? new Date(request.send_start_at) : null,
          end: request.send_end_at ? new Date(request.send_end_at) : null
        },
        verifyTime: {
          start: request.verify_start_at ? new Date(request.verify_start_at) : null,
          end: request.verify_end_at ? new Date(request.verify_end_at) : null
        },
        ...request
      })
    }
    return initialForm()
  }, [request])
  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.forestageSms.encodePath
  })
  const formValidation: FormValidation<AnnouncementSmsFormType> = useMemo(() => {
    return {
      sendTime: [],
      verifyTime: [],
      provider: [],
      phone: [],
      account: [],
      trigger: [],
      serial_number: [],
      send_status: [],
      receive_status: [],
      operator: [],
      is_verified: []
    }
  }, [])
  const triggerOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }].concat(
      Object.keys(smsTriggerNname)
        .map((key) => key as SmsTriggerType)
        .map((key) => ({ name: t(smsTriggerNname[key]), value: key })) as any
    )
  }, [t])
  const sendStatusOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }].concat(
      Object.keys(smsSendStatusName)
        .map((key) => key as SmsSendStatusType)
        .map((key) => ({ name: t(smsSendStatusName[key]), value: key })) as any
    )
  }, [t])
  const receiveStatusOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }].concat(
      Object.keys(smsReceiveStatusName)
        .map((key) => key as SmsReceiveStatusType)
        .map((key) => ({ name: t(smsReceiveStatusName[key]), value: key })) as any
    )
  }, [t])
  const operatorOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }].concat(
      Object.keys(smsOperatorNname)
        .map((key) => key as SmsOperatorType)
        .map((key) => ({ name: t(smsOperatorNname[key]), value: key })) as any
    )
  }, [t])
  const verifyOperations = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' },
      { name: t('common.verified'), value: 1 },
      { name: t('common.unverify'), value: 0 }
    ]
  }, [t])
  const writableProvider = useChecker([PermissionType.SMS_PROVIDER])
  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  return (
    <Paper>
      <Box padding={4}>
        <LoadingAndErrorFrame {...pageFlow.status}>
          {writableProvider && (
            <Box display="flex" justifyContent="flex-end" paddingBottom={2}>
              <Button
                className={classes.purpleGradualButton}
                onClick={handleOpen}
              >
                {t('common.smsProviderManagement')}
              </Button>
              <AnnouncementSmsDialog
                open={open}
                onClose={handleClose}
                providerStatus={result}
                reload={reload}
              />
            </Box>
          )}
          <Box
            paddingY={1.25}
            paddingX={2}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('page.sms')}
            </Typography>
          </Box>
          <FormStateProvider
            context={FormContext}
            defaultValue={defaultValue}
            onSubmit={handleSubmit}
            getValueFromEvent={getValueFromEvent}
            validation={formValidation}
          >
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <SendDateInput />
              </Grid>
              <Grid item xs={12} md={6}>
                <VerifyDateInput />
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<AnnouncementSmsFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="provider"
                      label={t('common.smsProvider')}
                      options={providerOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AnnouncementSmsFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="phone"
                      label={t('common.cellphone')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AnnouncementSmsFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="account"
                      label={t('common.sendObject')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<AnnouncementSmsFormType, TextFieldProps>
                      context={FormContext}
                      component={TextField}
                      name="serial_number"
                      label={t('common.smsId')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AnnouncementSmsFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="send_status"
                      label={t('common.sendStatus')}
                      options={sendStatusOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AnnouncementSmsFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="receive_status"
                      label={t('common.receiveStatus')}
                      options={receiveStatusOptions}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField<AnnouncementSmsFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="operator"
                      label={t('common.changeAccount')}
                      options={operatorOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AnnouncementSmsFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="is_verified"
                      label={t('common.verifyStatus')}
                      options={verifyOperations}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormField<AnnouncementSmsFormType, DropDownProps>
                      context={FormContext}
                      component={DropDown}
                      name="trigger"
                      label={t('common.triggerSence')}
                      options={triggerOptions}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="flex-end">
                  <FormSubmitButton
                    className={classes.purpleGradualButton}
                    context={FormContext}
                    type="submit"
                    component={Button}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </FormStateProvider>
          <AnnouncementSmsResendDialog
            providersStatus={result}
          />
        </LoadingAndErrorFrame>
      </Box>
    </Paper>
  )
}

export default React.memo(AnnouncementSmsForm)
