import React, { useCallback, useState } from 'react'
import { PlayerLayerForm, PlayerLayerItem } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import useT from '../../../i18ns/admin/useT'
import { useDialogHandleSubmit, useRedirectHandleBack, useGetDataByParams } from '../../../utils/default/ComplexFlowHook'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { Path } from '../../../components/admin/route/route'
import EditPlayerLayerForm, { EditPlayerLayerFormType, initialForm } from '../../../components/admin/player/EditPlayerLayerForm'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { formatMoney, formatCount } from '../../../utils/default/TableHelper'

const formToRequest: (form: EditPlayerLayerFormType) => Partial<PlayerLayerForm> = (form) => {
  return {
    name: form.name,
    register_days: Number(form.register),
    deposit_times: Number(form.depositCount),
    cash_sum_deposit: Number(form.depositCash),
    is_manual: form.isManual
  }
}

const PlayerLayerUpdatePage: React.FC = () => {
  const classes = useCommonStyles()
  const globalDialog = useGlobalDialog()
  const gdk = useGDK()
  const { t } = useT()
  const [id, setId] = useState<number>(0)
  const [defaultForm, setDefaulForm] = useState<EditPlayerLayerFormType>(initialForm())
  const pageFlow = usePageFlow()

  useGetDataByParams({
    path: Path.PLAYER_LAYER_UPDATE,
    onBeforeFetch: pageFlow.setLoadingStart,
    gdkFunc: (param: { id: string }) => gdk.player.getPlayerLayer(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onSuccess: (res: PlayerLayerItem) => {
      setId(res.id)
      setDefaulForm(initialForm({
        name: res.name,
        register: formatCount(res.register_days),
        depositCount: formatCount(res.deposit_times),
        depositCash: formatMoney(res.cash_sum_deposit),
        isManual: res.is_manual
      }))
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: id === 0
  })

  const [handleBack, handleDebouncedBack] = useRedirectHandleBack({
    path: Path.PLAYER_LAYER
  })

  const { handleSubmit } = useDialogHandleSubmit({
    dialogId: 'updatePlayerLayer',
    globalDialog,
    getChangeDialogConfig: useCallback((form: EditPlayerLayerFormType) => createGlobalDialogConfig({
      showIcon: false,
      message: (
        <React.Fragment>
          {t('dialog.confirmUpdatePlayerLayer')}
          <span className={classes.purpleWord}>
            {form.name}
          </span>
        </React.Fragment>
      )
    }), [t, classes.purpleWord]),
    getSuccessDialogConfig: useCallback(() => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.updatePlayerLayerSuccess')
    }), [t]),
    getFailDialogConfig: useCallback((error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }), []),
    formToRequest,
    gdkFunc: (payload) => gdk.player.updatePlayerLayer(id, payload),
    gdkFuncDependencies: [gdk, id],
    afterSuccessDialog: handleBack
  })

  return (
    <Box padding={5}>
      <LoadingAndErrorFrame { ...pageFlow.status }>
        <EditPlayerLayerForm
          title={t('page.updatePlayerLayer')}
          okText={t('common.confirmUpdate')}
          onBack={handleDebouncedBack}
          onSubmit={handleSubmit}
          defaultValue={defaultForm}
        />
      </LoadingAndErrorFrame>
    </Box>
  )
}

export default React.memo(PlayerLayerUpdatePage)
