import React, { useState, useMemo, useEffect } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import allRoute from '../route/route'
import { BalanceRetrieveRecordStatus } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import PointsCell from '../../default/present/PointsCell'
import { createTableData, formatMoney } from '../../../utils/default/TableHelper'
import CoreTable from '../../../components/default/present/CoreTable'
import BalanceRetrieveRecordDialog from '../BalanceRetrieveRecordDialog'
import { getTime } from 'date-fns'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'

interface PropTypes {
  id: number
  account: string
}

interface RowType {
  id: number
  cashLastBalance: JSX.Element | string
  cashAccounting: JSX.Element | string
  cashSelfBalance: JSX.Element | string
  difference: React.ReactElement | string
}

const WithdrawalAccountingTable: React.FC<PropTypes> = React.memo((props) => {
  const { id, account } = props
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  useEffect(() => {
    gdk.withdraw.withdrawSlipId = id
  }, [gdk, id])
  const res = useGDKStore.withdraw.withdrawSlipAccounting()

  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.nowrap
  }), [commonClasses])

  const rows: RowType[] = useMemo(() => [{
    id: 0,
    cashLastBalance: res.cash_last_balance
      ? (<span
        className={commonClasses.anchor}
        onClick={() => {
          setIsLastDetail(true)
          setIsDialogOpen(true)
        }}
      >
        {`${formatMoney(res.cash_last_balance)}${res.last_balances.filter((item) => item.status !== BalanceRetrieveRecordStatus.SUCCESS).length > 0 ? '*' : ''}`}
      </span>)
      : res.is_first_withdraw
        ? `0 ${t('common.isFirstWithdrawal')}`
        : t('common.noData'),
    cashAccounting: res.cash_accounting
      ? (<Link
        className={commonClasses.anchor}
        to={allRoute.playerCashAccounting.encodePath({ search: { start_at: getTime(res.start_at), end_at: getTime(res.end_at), account, page: 1 }, param: {} })}
        target="_blank"
      >
        {res.cash_accounting}
      </Link>)
      : t('common.uncountable'),
    cashSelfBalance: res.cash_self_balance
      ? (<span
        className={commonClasses.anchor}
        onClick={() => {
          setIsLastDetail(false)
          setIsDialogOpen(true)
        }}
      >
        {`${formatMoney(res.cash_self_balance)}${res.self_balances.filter((item) => item.status !== BalanceRetrieveRecordStatus.SUCCESS).length > 0 ? '*' : ''}`}
      </span>)
      : res.self_balances.length === 0 ? t('common.noDataShort') : `${t('common.balanceRetrieving')}...`,
    difference: res.difference ? (<PointsCell showSign points={res.difference} />) : t('common.uncountable')
  }], [res, t, commonClasses.anchor, account])

  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        cashLastBalance: {
          label: (
            <span className={commonClasses.pre}>
              {`${t('common.lastWithdraw')}\n${t('common.totalBalance')}`}
            </span>
          ),
          value: 'cashLastBalance',
          align: 'center'
        },
        cashAccounting: {
          label: (
            <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap">
              <Box paddingX={1}>+</Box>
              <span className={commonClasses.pre}>
                {t('common.cashAccount')}
              </span>
            </Box>
          ),
          value: 'cashAccounting',
          align: 'center'
        },
        cashSelfBalance: {
          label: (
            <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap">
              <Box paddingX={1}>-</Box>
              <span className={commonClasses.pre}>
                {`${t('common.thisWithdraw')}\n${t('common.totalBalance')}`}
              </span>
            </Box>
          ),
          value: 'cashSelfBalance',
          align: 'center'
        },
        difference: {
          label: (
            <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap">
              <Box paddingX={1}>=</Box>
              <span className={commonClasses.pre}>
                {t('common.difference')}
              </span>
            </Box>
          ),
          value: 'difference',
          align: 'center'
        }
      },
      [
        'cashLastBalance',
        'cashAccounting',
        'cashSelfBalance',
        'difference'
      ],
      rows,
      'id'
    )
  }, [rows, t, commonClasses.pre])

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isLastDetail, setIsLastDetail] = useState<boolean>(true)
  const details = useMemo(() => isLastDetail ? res.last_balances : res.self_balances, [isLastDetail, res])

  return (
    <Box overflow="auto" marginY={4}>
      <Box minWidth={400}>
        <Box
          paddingY={1}
          paddingX={1.5}
          className={clsx(commonClasses.blackTitleBar, commonClasses.pre)}
        >
          <Typography>
            {t('common.withdrawPeriodEstimate')}
          </Typography>
        </Box>
        <CoreTable
          classes={tableClasses}
          total={rows.length}
          data={data}
        />
        <Box paddingTop={1}>
          <Typography color="error" className={commonClasses.pre}>{t('common.withdrawPeriodEstimateTip')}</Typography>
        </Box>
        <BalanceRetrieveRecordDialog
          list={details}
          open={isDialogOpen}
          handleOk={() => {
            setIsDialogOpen(false)
          }}
        />
      </Box>
    </Box>
  )
})

export default React.memo(WithdrawalAccountingTable)
