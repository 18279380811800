import React, { useMemo, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CoreTable from '../../default/present/CoreTable'
import PointsCell from '../../default/present/PointsCell'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { createDefaultPaginationData, createTableData, formatMoney } from '../../../utils/default/TableHelper'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import allRoute from '../route/route'
import { PaginationRes, ProfitLayerInfoItem } from '@golden/gdk-admin'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useGetDataByPayload, useRequestFromSearch, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import { searchToRequest } from '../../../components/admin/adminReport/AdminReportProfitForm'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'

interface RowType {
  id: number
  betPlayerCount: number | '--'
  account: string
  effectiveCash: string
  bet: string
  result: string
  feedback: string
  activity: string
  deposit: string
  withdrawal: string
  profit: React.ReactElement
  donate: string
  buyback: string
  fee: string
  financeFee: string
  net: React.ReactElement
  other: string
  risk: string
  transferPoint?: string
}

const AdminReportProfitGoldenTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const navigate = useNavigate()
  const gdk = useGDK()
  const request = useRequestFromSearch({ searchToRequest })

  const rmbPageFlow = usePageFlow()
  const [rmb, setRMB] = useState<ProfitLayerInfoItem | null>(null)
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getProfitGolden(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: rmbPageFlow.setLoadingStart,
    onSuccess: (res: ProfitLayerInfoItem) => {
      setRMB(res)
      rmbPageFlow.setContentShow()
    },
    onError: (error) => {
      rmbPageFlow.setGDKError(error)
    },
    canLoadData: request !== undefined
  })

  const mainRows: RowType[] = useMemo(() => {
    return [{
      id: rmb?.id ?? 0,
      betPlayerCount: rmb?.bet_player_count ?? '--',
      account: rmb?.account ?? '',
      bet: formatMoney(rmb?.bet_cash ?? '0.0000'),
      result: formatMoney(rmb?.cash_winning ?? '0.0000'),
      feedback: formatMoney(rmb?.feedback ?? '0.0000'),
      activity: formatMoney(rmb?.activity ?? '0.0000'),
      deposit: formatMoney(rmb?.deposit ?? '0.0000'),
      withdrawal: formatMoney(rmb?.withdraw ?? '0.0000'),
      profit: (<PointsCell points={rmb?.revenue ?? '0.0000'} />),
      donate: formatMoney(rmb?.donate ?? '0.0000'),
      buyback: formatMoney(rmb?.buyback_amount ?? '0.0000'),
      fee: formatMoney(rmb?.platform_fee ?? '0.0000'),
      financeFee: formatMoney(rmb?.finance_fee ?? '0.0000'),
      net: (<PointsCell points={rmb?.net_revenue ?? '0.0000'} />),
      other: formatMoney(rmb?.other ?? '0.0000'),
      risk: formatMoney(rmb?.risk_adjustment ?? '0.0000'),
      effectiveCash: formatMoney(rmb?.effective_cash ?? '0.0000')
    }]
  }, [rmb])

  const mainData = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        betPlayerCount: {
          label: t('common.betPeople'),
          value: 'betPlayerCount',
          align: 'center'
        },
        account: {
          label: t('common.platform'),
          value: 'account',
          align: 'center'
        },
        effectiveCash: {
          label: t('common.successBetCash'),
          value: 'effectiveCash',
          align: 'right'
        },
        bet: {
          label: t('common.allBetCash'),
          value: 'bet',
          align: 'right'
        },
        result: {
          label: t('common.payedCash'),
          value: 'result',
          align: 'right'
        },
        feedback: {
          label: t('common.allFeedback'),
          value: 'feedback',
          align: 'right'
        },
        activity: {
          label: t('common.allActivityCash'),
          value: 'activity',
          align: 'right'
        },
        deposit: {
          label: t('common.allDepositCash'),
          value: 'deposit',
          align: 'right'
        },
        withdrawal: {
          label: t('common.allWithdrawalCash'),
          value: 'withdrawal',
          align: 'right'
        },
        profit: {
          label: t('common.totalWinAndLose'),
          value: 'profit',
          align: 'right'
        },
        donate: {
          label: t('common.totalDonate'),
          value: 'donate',
          align: 'right'
        },
        buyback: {
          label: t('common.totalBuyback'),
          value: 'buyback',
          align: 'right'
        },
        fee: {
          label: t('common.platformFee'),
          value: 'fee',
          align: 'right'
        },
        net: {
          label: t('common.netResult'),
          value: 'net',
          align: 'right'
        },
        financeFee: {
          label: t('common.financeFee'),
          value: 'financeFee',
          align: 'right'
        },
        other: {
          label: t('common.other'),
          value: 'other',
          align: 'right'
        },
        risk: {
          label: t('common.riskAdjustment'),
          value: 'risk',
          align: 'right'
        }
      },
      [
        'account',
        'betPlayerCount',
        'effectiveCash',
        'bet',
        'result',
        'buyback',
        'feedback',
        'activity',
        'fee',
        'risk',
        'profit',
        'financeFee',
        'net',
        'deposit',
        'withdrawal',
        'donate',
        'other'
      ],
      mainRows,
      'id'
    )
  }, [mainRows, t])

  const usersPageFlow = usePageFlow(true, false)
  const [users, setUsers] = useState<PaginationRes<ProfitLayerInfoItem[]>>(createDefaultPaginationData([]))
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.history.getProfitGoldenChildren({ ...payload, page: payload?.nextPage ?? 1 }),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: usersPageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ProfitLayerInfoItem[]>) => {
      setUsers(res)
      usersPageFlow.setContentShow()
    },
    onError: (error) => {
      usersPageFlow.setGDKError(error)
    },
    canLoadData: request !== undefined
  })

  const subRows = useMemo(() => {
    return users.data.map((child, index) => ({
      id: index,
      betPlayerCount: child.bet_player_count ?? '--',
      account: child.account,
      effectiveCash: formatMoney(child.effective_cash),
      bet: formatMoney(child.bet_cash),
      result: formatMoney(child.cash_winning),
      feedback: formatMoney(child.feedback),
      activity: formatMoney(child.activity),
      deposit: formatMoney(child.deposit),
      withdrawal: formatMoney(child.withdraw),
      profit: (<PointsCell points={child.revenue} />),
      donate: formatMoney(child.donate),
      buyback: formatMoney(child.buyback_amount),
      fee: formatMoney(child.platform_fee),
      financeFee: formatMoney(child.finance_fee),
      net: (<PointsCell points={child.net_revenue} />),
      other: formatMoney(child.other),
      risk: formatMoney(child.risk_adjustment),
      transferPoint: (child.transfer_point ? formatMoney(child.transfer_point) : '0.0000')
    })) as RowType[]
  }, [users.data])

  const subData = useMemo(() => {
    return createTableData(
      {
        id: {
          label: '',
          value: 'id'
        },
        effectiveCash: {
          label: t('common.successBetCash'),
          value: 'effectiveCash',
          align: 'right'
        },
        betPlayerCount: {
          label: t('common.betPeople'),
          value: 'betPlayerCount',
          align: 'center'
        },
        account: {
          label: t('common.player'),
          value: 'account',
          align: 'center'
        },
        bet: {
          label: t('common.allBetCash'),
          value: 'bet',
          align: 'right'
        },
        result: {
          label: t('common.payedCash'),
          value: 'result',
          align: 'right'
        },
        feedback: {
          label: t('common.allFeedback'),
          value: 'feedback',
          align: 'right'
        },
        activity: {
          label: t('common.allActivityCash'),
          value: 'activity',
          align: 'right'
        },
        deposit: {
          label: t('common.allDepositCash'),
          value: 'deposit',
          align: 'right'
        },
        withdrawal: {
          label: t('common.allWithdrawalCash'),
          value: 'withdrawal',
          align: 'right'
        },
        profit: {
          label: t('common.totalWinAndLose'),
          value: 'profit',
          align: 'right'
        },
        donate: {
          label: t('common.totalDonate'),
          value: 'donate',
          align: 'right'
        },
        buyback: {
          label: t('common.totalBuyback'),
          value: 'buyback',
          align: 'right'
        },
        fee: {
          label: t('common.platformFee'),
          value: 'fee',
          align: 'right'
        },
        financeFee: {
          label: t('common.financeFee'),
          value: 'financeFee',
          align: 'right'
        },
        net: {
          label: t('common.netResult'),
          value: 'net',
          align: 'right'
        },
        other: {
          label: t('common.other'),
          value: 'other',
          align: 'right'
        },
        risk: {
          label: t('common.riskAdjustment'),
          value: 'risk',
          align: 'right'
        },
        transferPoint: {
          label: t('common.transferPoint'),
          value: 'transferPoint',
          align: 'right'
        }
      },
      [
        'account',
        'betPlayerCount',
        'effectiveCash',
        'bet',
        'result',
        'buyback',
        'feedback',
        'activity',
        'fee',
        'risk',
        'profit',
        'financeFee',
        'net',
        'deposit',
        'withdrawal',
        'donate',
        'transferPoint',
        'other'
      ],
      subRows,
      'id'
    )
  }, [t, subRows])

  const tableClasses = useMemo(() => {
    return {
      head: classes.greyTableHead,
      cellHead: classes.tableCellHead,
      row: classes.tableRow
    }
  }, [classes])

  const handleChangePage = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.adminReportProfitLayer.encodePath,
    param: { id: 'RMB' },
    pageKey: 'nextPage'
  })

  return (
    <React.Fragment>
      <ScrollablePaper marginX={6}>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            marginBottom={3}
            className={classes.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('page.adminReportProfitLayer')}
            </Typography>
          </Box>
          <Box paddingBottom={6} width="100%">
            <LoadingAndErrorFrame { ...rmbPageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={mainData}
                total={1}
              />
            </LoadingAndErrorFrame>
          </Box>
          <LoadingAndErrorFrame { ...usersPageFlow.status }>
            <CoreTable
              classes={tableClasses}
              data={subData}
              loading={usersPageFlow.status.loading}
              total={users.total}
              showPagination
              page={request?.nextPage ?? 1}
              onChangePage={handleChangePage}
              hasHeadWithNoData
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
      <Box paddingX={6} paddingTop={2} display="flex" justifyContent="flex-end">
        <Button
          component={Link}
          to={allRoute.adminReportProfit.encodePath({ search: { ...request }, param: {} })}
          className={classes.greyButton}
        >
          {t('common.back')}
        </Button>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(AdminReportProfitGoldenTable)
