import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, formatCount, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import { PaginationRes, ActivityDepositBonus, ActivityDepositBonusSum, ActivityDepositBonusQuery, PaginationReq, ActivityType } from '@golden/gdk-admin'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordDetailForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import DateTime from '../../default/present/DateTime'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import CoreTable from '../../default/present/CoreTable'
import allRoute, { Path } from '../route/route'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import PointsCell from '../../default/present/PointsCell'
import { depositBonusStatusName } from '../../../constants/default/activityStatusName'
import { depositBonusStatusColor } from '../../../constants/default/activityStatusColor'
import { useLocation } from 'react-router-dom'

interface SumRowType1 {
  id: number
  bonusCount: string
  totalBonus: string
}

interface RowType {
  id: number
  order: string
  apply: React.ReactElement
  account: string
  staffAccount: string
  content: string
  status: React.ReactElement
  bonus: string
  updatedAt: React.ReactElement
  memo: React.ReactElement
}

const MemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const AdminReportActivityDepositBonusTable: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const activities = useGDKStore.activity.activities()
  const location = useLocation()
  const [list, setList] = useState<PaginationRes<ActivityDepositBonus[]> & ActivityDepositBonusSum>({
    ...createDefaultPaginationData([]),
    bonus_count: 0,
    total_bonus: '0.00'
  })
  const pageFlow = usePageFlow()
  const request = useRequestFromSearch({ searchToRequest })

  useGetDataByPayload({
    payload: request as ActivityDepositBonusQuery & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityRecordDepositBonus(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityDepositBonus[]> & ActivityDepositBonusSum) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: classes.greyTableHead,
    row: classes.tableRow,
    cellHead: classes.tableCellHead,
    cellBody: classes.nowrap
  }), [classes])
  const sumRow = useMemo(() => {
    return [{
      id: 1,
      bonusCount: formatCount(list.bonus_count),
      totalBonus: formatMoney(list.total_bonus)
    }] as SumRowType1[]
  }, [list])
  const sumData = useMemo(() => {
    return createTableData<SumRowType1>(
      {
        id: {
          label: '',
          value: 'id'
        },
        bonusCount: {
          label: t('common.activityTotalCount'),
          value: 'bonusCount',
          align: 'center'
        },
        totalBonus: {
          label: t('common.totalActivityBonus'),
          value: 'totalBonus',
          align: 'center'
        }
      },
      [
        'bonusCount',
        'totalBonus'
      ],
      sumRow,
      'id'
    )
  }, [t, sumRow])

  const rows = useMemo(() => {
    return list.data.map((item, index) => {
      return {
        id: index,
        order: item.serial_number,
        apply: (<DateTime time={item.applied_at} />),
        account: item.account,
        staffAccount: item.staff_account,
        content: item.activity_content,
        status: (<p className={classes.chipText} style={{ backgroundColor: depositBonusStatusColor[item.status] }}>{t(depositBonusStatusName[item.status])}</p>),
        bonus: formatMoney(item.bonus),
        updatedAt: item.updated_at ? (<DateTime time={item.updated_at} />) : (<p></p>),
        memo: item.memo && (<MemoTextWithTitleAndContext context={MemoContext} title={item.memo} memo={item.memo} />)
      } as RowType
    })
  }, [list.data, t, classes])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        order: {
          label: t('common.activitySerial'),
          value: 'order',
          align: 'center'
        },
        apply: {
          label: t('common.applyTime'),
          value: 'apply',
          align: 'center'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        staffAccount: {
          label: t('common.staffAccount'),
          value: 'staffAccount',
          align: 'center'
        },
        content: {
          label: t('common.activityContent'),
          value: 'content',
          align: 'left'
        },
        status: {
          label: t('common.status'),
          value: 'status',
          align: 'center'
        },
        bonus: {
          label: t('common.activityBonus'),
          value: 'bonus',
          align: 'right'
        },
        updatedAt: {
          label: t('common.updatedAt'),
          value: 'updatedAt',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        }
      },
      [
        'order',
        'apply',
        'account',
        'staffAccount',
        'content',
        'status',
        'bonus',
        'updatedAt',
        'memo'
      ],
      rows,
      'id'
    )
  }, [t, rows])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: (location.pathname === Path.ACTIVITY_RECORD_DETAIL) ? allRoute.activityRecordDetail.encodePath : allRoute.activityRecordSub.encodePath
  })
  return (
    <MemoPopoverWithContext memoPopoverContext={MemoContext}>
      <Box paddingBottom={3}>
        <ScrollablePaper marginX={5}>
          <Box padding={5}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {activities.find((item) => item.type === ActivityType.DEPOSIT_BONUS)?.name ?? ''}
                </Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box paddingY={2}>
                    <Typography variant="h5">
                      {t('common.activityStatisticResult')}
                    </Typography>
                  </Box>
                  <CoreTable
                    data={sumData}
                    total={1}
                    classes={tableClasses}
                  />
                </Grid>
              </Grid>
            </LoadingAndErrorFrame>
          </Box>
        </ScrollablePaper>
      </Box>
      <ScrollablePaper marginX={5}>
        <Box padding={5}>
          <LoadingAndErrorFrame { ...pageFlow.status }>
            <CoreTable
              data={data}
              total={list.total}
              classes={tableClasses}
              showPagination
              page={request?.page ?? 1}
              onChangePage={handlePagination}
            />
          </LoadingAndErrorFrame>
        </Box>
      </ScrollablePaper>
    </MemoPopoverWithContext>
  )
}

export default React.memo(AdminReportActivityDepositBonusTable)
