import React, { useState, createContext, useContext, useCallback, Dispatch, SetStateAction, useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useGetDataByPayload, useReload, InitialFormFunc, useDialogHandleSubmit } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AdminPlayerSetting, AdminPlayerSettingForm, PermissionType, FinanceRoleType, PlayerStatusType, GameType, GameStatus } from '@golden/gdk-admin'
import { ValueGetter, FormValidation, createDefaultFormState, ChangedFormGetter } from '../../../utils/default/FormHook'
import { getValueFromCheckboxEvent, getValueFromValue } from '../../../utils/default/FormHelper'
import FormStateProvider from '../../default/form/FormStateProvider'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import FormField from '../../default/form/FormField'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import MultipleSelector, { PropTypes as MultipleSelectorPropTypes } from '../../default/form/MultipleSelector'
import financeRoleTypeName from '../../../constants/admin/financeRoleTypeName'
import { InfoContext } from '../../../views/admin/player/PlayerDetailPage'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import GameIdInput, { PropTypes as GameIdProps } from '../GameIdInput'
import { isUndefined, omitBy } from '@golden/utils'

const useStyles = makeStyles((theme) => ({
  text: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  unhandledDeposit: {
    width: '1.5em',
    height: '1.5em',
    backgroundColor: '#eee',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

interface PropTypes {
  id: number
  section: string
  reloadFlag: boolean
  reload: () => void
  loaded: Dispatch<SetStateAction<string>>
}

interface FormType {
  fmWarned: boolean
  rcWarned: boolean
  isFirstWithdrawLimit: boolean
  isPasswordBlocked: boolean
  isSuspended: boolean
  notBetable: boolean
  notDepositable: boolean
  notWithdrawable: boolean
  notTransferable: boolean
  notActivity: boolean
  smsVerifyBlocked: boolean
  financeRolesDeposit: FinanceRoleType[]
  financeRolesWithdraw: FinanceRoleType[]
  forbiddenGames: GameType[]
}

const initialForm: InitialFormFunc<FormType> = (defaultForm) => ({
  fmWarned: false,
  rcWarned: false,
  isFirstWithdrawLimit: false,
  isPasswordBlocked: false,
  isSuspended: false,
  notBetable: false,
  notDepositable: false,
  notWithdrawable: false,
  notTransferable: false,
  notActivity: false,
  smsVerifyBlocked: false,
  financeRolesDeposit: [],
  financeRolesWithdraw: [],
  forbiddenGames: [],
  ...defaultForm
})

const FormContext = createContext(createDefaultFormState(initialForm()))

const getValueFromEvent: ValueGetter<FormType> = {
  fmWarned: getValueFromCheckboxEvent,
  rcWarned: getValueFromCheckboxEvent,
  isFirstWithdrawLimit: getValueFromCheckboxEvent,
  isPasswordBlocked: getValueFromCheckboxEvent,
  isSuspended: getValueFromCheckboxEvent,
  notBetable: getValueFromCheckboxEvent,
  notDepositable: getValueFromCheckboxEvent,
  notWithdrawable: getValueFromCheckboxEvent,
  notTransferable: getValueFromCheckboxEvent,
  notActivity: getValueFromCheckboxEvent,
  smsVerifyBlocked: getValueFromCheckboxEvent,
  financeRolesDeposit: getValueFromValue,
  financeRolesWithdraw: getValueFromValue,
  forbiddenGames: getValueFromValue
}

const validation: FormValidation<FormType> = {
  fmWarned: [],
  rcWarned: [],
  isFirstWithdrawLimit: [],
  isPasswordBlocked: [],
  isSuspended: [],
  notBetable: [],
  notDepositable: [],
  notWithdrawable: [],
  notTransferable: [],
  notActivity: [],
  smsVerifyBlocked: [],
  financeRolesDeposit: [],
  financeRolesWithdraw: [],
  forbiddenGames: []
}

const Button = React.memo(MuiButton)

const allWithdrawFinanceRolesLength = Object.keys(financeRoleTypeName).map((key) => key as FinanceRoleType).filter((item) => (item.includes('withdraw'))).length

const PermissionStatus: React.FC = () => {
  const { value } = useContext(FormContext)
  const { t } = useT()
  const [playerInfo] = useContext(InfoContext)

  const showStatusNormal = useMemo(() => {
    return (
      !value?.isPasswordBlocked &&
      !value?.isSuspended &&
      !value?.notBetable &&
      !value?.notDepositable &&
      !value?.notWithdrawable &&
      !value?.notTransferable &&
      !value?.notActivity &&
      !value?.smsVerifyBlocked &&
      !value?.financeRolesDeposit.length &&
      !value?.financeRolesWithdraw.length &&
      !value?.forbiddenGames.length &&
      !value?.isFirstWithdrawLimit &&
      playerInfo.status !== PlayerStatusType.NO_CARD
    )
  }, [value, playerInfo])

  const showStatusNotLoginable = useMemo(() => {
    return (value?.isSuspended || value?.isPasswordBlocked)
  }, [value])

  const showStatusNotDepositable = useMemo(() => {
    return (value?.isPasswordBlocked || value?.isSuspended || value?.notDepositable || value?.financeRolesDeposit.length !== 0)
  }, [value])

  const showStatusNotDepositableNumber = useMemo(() => {
    return (!value?.isSuspended && !value?.notDepositable && value?.financeRolesDeposit.length !== 0)
  }, [value])

  const showStatusNotWithdrawable = useMemo(() => {
    return (playerInfo.status === PlayerStatusType.NO_CARD || value?.isPasswordBlocked || value?.isFirstWithdrawLimit || value?.isSuspended || value?.notWithdrawable || value?.financeRolesWithdraw.length !== 0)
  }, [value, playerInfo])

  const showStatusNotWithdrawableNumber = useMemo(() => {
    return (!value?.isSuspended && !value?.notWithdrawable && !(value?.isFirstWithdrawLimit && value.financeRolesWithdraw.includes(FinanceRoleType.NOT_WITHDRAW_DEBIT_CARD)) && (value?.financeRolesWithdraw.length !== 0 || value?.isFirstWithdrawLimit) && playerInfo.status !== PlayerStatusType.NO_CARD)
  }, [value, playerInfo])

  const showStatusNotTransferable = useMemo(() => {
    return (value?.isPasswordBlocked || value?.isSuspended || value?.notTransferable)
  }, [value])

  const showStatusNotActivity = useMemo(() => {
    return (value?.isSuspended || value?.notActivity)
  }, [value])

  const showStatusNotBetable = useMemo(() => {
    return (value?.isPasswordBlocked || value?.isSuspended || value?.notBetable || value?.forbiddenGames.length !== 0)
  }, [value])

  const showStatusNotBetableNumber = useMemo(() => {
    return (!value?.isSuspended && !value?.notBetable && value?.forbiddenGames.length !== 0)
  }, [value])

  const showStatusSmsVerifyBlocked = useMemo(() => {
    return (value?.isPasswordBlocked || value?.smsVerifyBlocked || value?.isSuspended)
  }, [value])

  return (
    <Box paddingY={1}>
      <Grid container direction="row" spacing={3}>
        {showStatusNormal && (
          <Grid item>
            <Typography>{t('userStatus.normal')}</Typography>
          </Grid>
        )}
        {showStatusNotLoginable && (
          <Grid item>
            <Typography color="error">{t('roleType.notLoginable')}</Typography>
          </Grid>
        )}
        {showStatusNotDepositable && (
          <Grid item>
            <Typography color="error">
              {t('roleType.notDepositable')}
              {showStatusNotDepositableNumber && (
                <span>{`(${value.financeRolesDeposit.length})`}</span>
              )}
            </Typography>
          </Grid>
        )}
        {showStatusNotWithdrawable && (
          <Grid item>
            <Typography color="error">
              {t('roleType.notWithdrawable')}
              {showStatusNotWithdrawableNumber && (
                <span>{`(${(value.isFirstWithdrawLimit) ? allWithdrawFinanceRolesLength - 1 : value.financeRolesWithdraw.length})`}</span>
              )}
            </Typography>
          </Grid>
        )}
        {showStatusNotTransferable && (
          <Grid item>
            <Typography color="error">{t('roleType.notTransferable')}</Typography>
          </Grid>
        )}
        {showStatusNotActivity && (
          <Grid item>
            <Typography color="error">{t('roleType.notActivity')}</Typography>
          </Grid>
        )}
        {showStatusNotBetable && (
          <Grid item>
            <Typography color="error">
              {t('roleType.notBetable')}
              {showStatusNotBetableNumber && (
                <span>{`(${value.forbiddenGames.length})`}</span>
              )}
            </Typography>
          </Grid>
        )}
        {showStatusSmsVerifyBlocked && (
          <Grid item>
            <Typography color="error">{t('roleType.smsVerifyBlocked')}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
const PlayerStateCheckBoxes: React.FC = () => {
  const { value } = useContext(FormContext)
  const { t } = useT()
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_PERMISSION])

  const financeRolesForbidOptions = useCallback((type: 'deposit' | 'withdraw') => {
    return Object.keys(financeRoleTypeName)
      .map((key) => key as FinanceRoleType)
      .map((key) => ({ name: t(financeRoleTypeName[key]), value: key }))
      .filter((item) => (item.value.includes(type)))
  }, [t])

  return (
    <Box marginBottom={4}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={3} lg={2}>
          <FormField<FormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            context={FormContext}
            name="isSuspended"
            label={t('common.freezeAccount')}
            disabled={!writable}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <FormField<FormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            value={value.isSuspended || value.notTransferable }
            context={FormContext}
            name="notTransferable"
            label={t('common.forbidTransfer')}
            disabled={!writable || value.isSuspended}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <FormField<FormType, OnOffCheckboxProps>
            component={OnOffCheckbox}
            value={value.isSuspended || value.notActivity }
            context={FormContext}
            name="notActivity"
            label={t('common.forbidActivity')}
            disabled={!writable || value.isSuspended }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormField<FormType, GameIdProps>
            context={FormContext}
            component={GameIdInput}
            name="forbiddenGames"
            label={t('common.forbidBet')}
            fullWidth
            disabled={!writable || value.isSuspended }
            multiple
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormField<FormType, MultipleSelectorPropTypes>
            context={FormContext}
            component={MultipleSelector}
            name="financeRolesDeposit"
            label={t('common.forbidDeposit')}
            options={financeRolesForbidOptions('deposit')}
            fullWidth
            disabled={!writable || value.isSuspended}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormField<FormType, MultipleSelectorPropTypes>
            context={FormContext}
            component={MultipleSelector}
            name="financeRolesWithdraw"
            label={t('common.forbidWithdraw')}
            options={financeRolesForbidOptions('withdraw')}
            fullWidth
            disabled={!writable || value.isSuspended}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
const PlayerDetailPermissionSection: React.FC<PropTypes> = (props) => {
  const { id, section, reloadFlag: reloadFlagProps, reload: reloadProps, loaded } = props

  const gdk = useGDK()
  const writable = useChecker([PermissionType.PLAYER_MANAGEMENT_PERMISSION])
  const globalDialog = useGlobalDialog()
  const { reload, reloadFlag } = useReload()
  const pageFlow = usePageFlow()
  const allGames = useGDKStore.platform.games()
  const aliveGames = useMemo(() => useGDKStore.platform.aliveGames(), [allGames])
  const { t } = useT()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const [isPasswordBlocked, setIsPasswordBlocked] = useState<boolean>(true)
  const [isSmsVerifyBlocked, setIsSmsVerifyBlocked] = useState<boolean>(true)
  const [isFirstWithdrawLimit, setIsFirstWithdrawLimit] = useState<boolean>(true)
  const [hasCurrencyWithdraw, setHasCurrencyWithdraw] = useState<boolean>(false)
  const [defaultForm, setDefaultForm] = useState<FormType>(initialForm())
  const [playerInfo] = useContext(InfoContext)

  const allFinanceRoles = Object.keys(financeRoleTypeName).map((key) => key as FinanceRoleType)

  const getChangedForm: ChangedFormGetter<FormType> = {
    financeRolesDeposit: (value, form) => (
      {
        ...form,
        financeRolesDeposit: value,
        notDepositable: (value.length === allFinanceRoles.filter((el) => (el.includes('deposit'))).length)
      }),
    financeRolesWithdraw: (value, form) => (
      {
        ...form,
        financeRolesWithdraw: value,
        notWithdrawable: (value.length === allFinanceRoles.filter((el) => (el.includes('withdraw'))).length)
      }),
    forbiddenGames: (value, form) => (
      {
        ...form,
        forbiddenGames: value,
        notBetable: (value.length === aliveGames.length)
      })
  }

  const formToRequest = (form: FormType): AdminPlayerSettingForm => {
    const converted = {
      fm_warned: form.fmWarned,
      rc_warned: form.rcWarned,
      is_first_withdraw_limit: form.isFirstWithdrawLimit === isFirstWithdrawLimit ? undefined : form.isFirstWithdrawLimit, // checking whether firstWithdrawLimit form has been changed
      is_password_blocked: form.isPasswordBlocked,
      is_suspended: form.isSuspended,
      not_betable: form.notBetable,
      not_depositable: form.notDepositable,
      not_withdrawable: form.notWithdrawable,
      not_transferable: form.notTransferable,
      not_activity: form.notActivity,
      not_sms_verify_sendable: form.smsVerifyBlocked,
      finance_roles: form.financeRolesDeposit.concat(form.financeRolesWithdraw),
      forbidden_games: form.forbiddenGames
    } as AdminPlayerSettingForm
    return omitBy(converted, isUndefined) as AdminPlayerSettingForm
  }

  useGetDataByPayload({
    payload: id,
    gdkFunc: (payload) => gdk.player.getPlayerSetting(payload),
    gdkFuncDependencies: [gdk, reloadFlag, reloadFlagProps, aliveGames],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: AdminPlayerSetting) => {
      setIsPasswordBlocked(res.state.is_password_blocked)
      setIsSmsVerifyBlocked(res.state.not_sms_verify_sendable)
      setIsFirstWithdrawLimit(res.state.is_first_withdraw_limit)
      setHasCurrencyWithdraw(res.has_currency_withdraw)
      setDefaultForm(initialForm({
        fmWarned: res.state.fm_warned,
        rcWarned: res.state.rc_warned,
        isFirstWithdrawLimit: res.state.is_first_withdraw_limit,
        isPasswordBlocked: res.state.is_password_blocked,
        isSuspended: res.state.is_suspended,
        notBetable: (res.state.not_betable),
        notDepositable: (res.state.not_depositable),
        notWithdrawable: (res.state.not_withdrawable),
        notTransferable: (res.state.not_transferable),
        notActivity: (res.state.not_activity),
        smsVerifyBlocked: (res.state.not_sms_verify_sendable),
        financeRolesDeposit: (res.state.not_depositable) ? allFinanceRoles.filter((el) => (el.includes('deposit'))) : res.finance_roles.filter((el) => (el.includes('deposit'))),
        financeRolesWithdraw: (res.state.not_withdrawable) ? allFinanceRoles.filter((el) => (el.includes('withdraw'))) : res.finance_roles.filter((el) => (el.includes('withdraw'))),
        forbiddenGames: (res.state.not_betable) ? aliveGames.map((game) => (game.id)) : (res.forbidden_games)
      }))
      pageFlow.setContentShow()
      loaded(section)
    },
    onSuccessDependencies: [aliveGames],
    onError: pageFlow.setGDKError
  })
  const { handleSubmit } = useDialogHandleSubmit({
    formToRequest,
    globalDialog,
    dialogId: 'updatePlayerSetting',
    gdkFunc: (payload) => gdk.player.updatePlayerSetting(id, payload),
    gdkFuncDependencies: [gdk, id],
    getChangeDialogConfig: (form) => createGlobalDialogConfig({
      showIcon: false,
      notUseTypo: true,
      message: (
        <React.Fragment>
          <Typography className={dialogClasses.text}>{t('dialog.confirmPlayerSetting')}</Typography>
          {
            (form.fmWarned ||
            form.rcWarned ||
            form.isFirstWithdrawLimit ||
            form.isSuspended ||
            form.isPasswordBlocked ||
            form.notBetable ||
            form.notDepositable ||
            form.notWithdrawable ||
            form.notTransferable ||
            form.notActivity ||
            form.smsVerifyBlocked ||
            (form.financeRolesDeposit.length !== 0) ||
            (form.financeRolesWithdraw.length !== 0) ||
            (form.forbiddenGames.length !== 0)) && (
              <Typography className={clsx(dialogClasses.text, commonClasses.purpleWord)}>
                [<span className={classes.text}>{t('dialog.forbidFunctionWithColon')}</span>
                {form.fmWarned && (<span className={classes.text}>{`${t('common.finance')} ${t('common.warning')}`}</span>)}
                {form.rcWarned && (<span className={classes.text}>{`${t('common.riskControl')} ${t('common.warning')}`}</span>)}
                {form.isSuspended && (<span className={classes.text}>{t('common.freezeAccount')}</span>)}
                {form.isPasswordBlocked && (<span className={classes.text}>{t('common.freezeAccountPwdWrong')}</span>)}
                {(form.notBetable || form.forbiddenGames.length !== 0) && (<span className={classes.text}>{t('common.forbidBet')}</span>)}
                {(!form.notBetable && form.forbiddenGames.length !== 0) && (<span className={classes.text}>{`(${form.forbiddenGames.length})`}</span>)}
                {(form.notDepositable || (form.financeRolesDeposit.length !== 0)) && (<span className={classes.text}>{t('common.forbidDeposit')}</span>)}
                {(!form.notDepositable && form.financeRolesDeposit.length !== 0) && (<span className={classes.text}>{`(${form.financeRolesDeposit.length})`}</span>)}
                {(form.isFirstWithdrawLimit || form.notWithdrawable || (form.financeRolesWithdraw.length !== 0)) && (<span className={classes.text}>{t('common.forbidWithdraw')}</span>)}
                {((!form.isSuspended && !form.notWithdrawable && !(form.isFirstWithdrawLimit && form.financeRolesWithdraw.includes(FinanceRoleType.NOT_WITHDRAW_DEBIT_CARD)) && (form.financeRolesWithdraw.length !== 0 || form.isFirstWithdrawLimit) && playerInfo.status !== PlayerStatusType.NO_CARD)) && (<span className={classes.text}>{form.isFirstWithdrawLimit ? `(${allWithdrawFinanceRolesLength - 1})` : `(${form.financeRolesWithdraw.length})`}</span>)}
                {form.notTransferable && (<span className={classes.text}>{t('common.forbidTransfer')}</span>)}
                {form.notActivity && (<span className={classes.text}>{t('common.forbidActivity')}</span>)}
                {form.smsVerifyBlocked && (<span className={classes.text}>{t('common.sms')}</span>)}]
              </Typography>
            )
          }
        </React.Fragment>
      )
    }),
    getSuccessDialogConfig: () => createGlobalDialogConfig({
      showIcon: false,
      showCancel: false,
      message: t('dialog.playerSettingSuccess')
    }),
    getFailDialogConfig: (error) => createGlobalDialogConfig({
      showIcon: true,
      variant: 'error',
      showCancel: false,
      message: error
    }),
    onSuccess: () => {
      reload()
      reloadProps()
    }
  })
  return (
    <div id={section}>
      <FormStateProvider
        context={FormContext}
        defaultValue={defaultForm}
        onSubmit={handleSubmit}
        getValueFromEvent={getValueFromEvent}
        getChangedForm={getChangedForm}
        validation={validation}
      >
        <Paper>
          <Box padding={4}>
            <Box
              paddingY={1.25}
              paddingX={2}
              className={commonClasses.pinkTitleBar}
            >
              <Typography variant="h5">
                {t('common.playerPermissionSetting')}
              </Typography>
            </Box>
            <Box paddingY={2}>
              <LoadingAndErrorFrame { ...pageFlow.status }>
                <Typography variant="h5">
                  {t('common.status')}
                </Typography>
                <PermissionStatus />
                <Box paddingY={2}>
                  <Typography variant="h5">
                    {t('common.setting')}
                  </Typography>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={3} lg={2}>
                      <FormField<FormType, OnOffCheckboxProps>
                        component={OnOffCheckbox}
                        context={FormContext}
                        name="fmWarned"
                        label={`${t('common.finance')} ${t('common.warning')}`}
                        disabled={!writable}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={2}>
                      <FormField<FormType, OnOffCheckboxProps>
                        component={OnOffCheckbox}
                        context={FormContext}
                        name="rcWarned"
                        label={`${t('common.riskControl')} ${t('common.warning')}`}
                        disabled={!writable}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={2}>
                      <FormField<FormType, OnOffCheckboxProps>
                        component={OnOffCheckbox}
                        context={FormContext}
                        name="isPasswordBlocked"
                        label={t('common.freezeAccountPwdWrong')}
                        disabled={!writable || !isPasswordBlocked}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={2}>
                      <FormField<FormType, OnOffCheckboxProps>
                        component={OnOffCheckbox}
                        context={FormContext}
                        name="isFirstWithdrawLimit"
                        label={t('common.firstWithdrawLimit')}
                        disabled={!writable || hasCurrencyWithdraw}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <FormField<FormType, OnOffCheckboxProps>
                        component={OnOffCheckbox}
                        context={FormContext}
                        name="smsVerifyBlocked"
                        label={t('common.smsVerifyBlockedCheckbox')}
                        disabled={!writable || !isSmsVerifyBlocked}
                      />
                    </Grid>
                  </Grid>
                  <PlayerStateCheckBoxes />
                </Box>
                {writable && (
                  <Box display="flex" justifyContent="flex-end">
                    <FormSubmitButton
                      context={FormContext}
                      component={Button}
                      className={commonClasses.purpleGradualButton}
                      type="submit"
                    >
                      {t('common.saveSetting')}
                    </FormSubmitButton>
                  </Box>
                )}
              </LoadingAndErrorFrame>
            </Box>
          </Box>
        </Paper>
      </FormStateProvider>
    </div>
  )
}

export default React.memo(PlayerDetailPermissionSection)
