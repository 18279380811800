import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import ScrollablePaper from '../../../components/default/present/ScrollablePaper'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import CoreTable from '../../../components/default/present/CoreTable'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { AgCategoryType, Channel, GameStatus, GameType, PaginationRes } from '@golden/gdk-admin'
import { useGetDataByPayload, useReload, useRequestFromSearch, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { createDefaultPaginationData, createTableData } from '../../../utils/default/TableHelper'
import ExternalGameChannelChangeButton from '../../../components/admin/externalGame/ExternalGameChannelChangeButton'
import ExternalGameChannelDeleteButton from './ExternalGameChannelDeleteButton'
import ExternalGameChannelUpdateImageButton from './ExternalGameChannelUpdateImageButton'
import { initialForm } from './ExternalGameChannelDialog'
import { timer } from 'rxjs'
import { searchToRequest } from './ExternalGameChannelForm'
import allRoute from '../route/route'
import { findGame } from '../../../utils/default/PlatformHelper'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import { GameBase } from '@golden/game-china'

const useStyles = makeStyles(() => ({
  checked: {
    color: '#72d476'
  },
  cancel: {
    color: '#ff5f5f'
  }
}))

interface RowType {
  id: number
  code: string
  name: string
  category: string
  agGciType: string
  image: React.ReactElement
  isShow: React.ReactElement
  functions: React.ReactElement
}

const ExternalGameChannelTable: React.FC = () => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const pageFlow = usePageFlow()
  const gdk = useGDK()
  const aliveGames = useGDKStore.platform.aliveGames()
  const writable = useChecker()
  const { t } = useT()
  const { reload, reloadFlag } = useReload()
  const [loadFromUnsynced, setLoadFromUnsynced] = useState<boolean>(false)
  const [finishFromSynced, setFinishFromSynced] = useState<boolean>(false)
  const [loadCount, setLoadCount] = useState<number>(1)
  const request = useRequestFromSearch({ searchToRequest })
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const game: GameBase | null = useMemo(() => findGame(request!.gameId as GameType, aliveGames)?.instance ?? null, [aliveGames, request])
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const page = useMemo(() => request!.page, [request])
  const [res, setRes] = useState<PaginationRes<Channel[]>>(createDefaultPaginationData([]))

  useEffect(() => {
    setLoadCount(1)
  }, [game])

  useEffect(() => {
    setLoadCount((loadCount) => loadCount + 1)
  }, [page, reloadFlag])

  useEffect(() => {
    if (game?.customGameHallSetting?.externalGameChannelSetting.isSync) {
      setLoadFromUnsynced(false)
      const subscription = timer(1000 * 5).subscribe({
        next: () => {
          if (!finishFromSynced) {
            setLoadFromUnsynced(true)
          }
        },
        error: () => 1
      })
      return () => subscription.unsubscribe()
    } else {
      setLoadFromUnsynced(true)
    }
  }, [game, finishFromSynced])

  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: request!,
    gdkFunc: (payload) => gdk.platform.getChannels(payload.gameId, payload.page),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<Channel[]>) => {
      setRes(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: loadFromUnsynced || loadCount > 1
  })

  useGetDataByPayload({
    payload: game?.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gdkFunc: (payload) => gdk.platform.syncedChannel(payload!),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: () => {
      setFinishFromSynced(false)
      pageFlow.setLoadingStart()
    },
    onSuccess: (res: PaginationRes<Channel[]>) => {
      setRes(res)
      setFinishFromSynced(true)
      pageFlow.setContentShow()
    },
    canLoadData: !!game && !loadFromUnsynced
  })

  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])

  const rows: RowType[] = useMemo(() => {
    if (!game) return []
    return res.data.map((item) => {
      return {
        id: item.id,
        code: item.code,
        name: item.name,
        category: game?.channelCategories.find((el) => el.id === item.category)?.text ?? '',
        agGciType: item.ag_gci_type ?? '',
        isShow: item.is_show ? (<CheckCircleIcon className={classes.checked} />) : (<CancelIcon className={classes.cancel} />),
        image: (
          <ExternalGameChannelUpdateImageButton
            id={item.id}
            name={item.name}
            imageId={item.image_id}
            imagePath={item.image_path}
            reload={reload}
          />
        ),
        functions: (
          <Grid container spacing={2} justifyContent='center'>
            <Grid item>
              <ExternalGameChannelChangeButton
                id={item.id}
                game={game}
                form={initialForm({
                  code: item.code,
                  name: item.name,
                  category: item.category ?? AgCategoryType.SLOT,
                  agGciType: item.ag_gci_type ?? '',
                  isShow: item.is_show
                })}
                reload={reload}
                isPersist={item.is_persist}
              />
            </Grid>
            <Grid item>
              <ExternalGameChannelDeleteButton
                id={item.id}
                name={item.name}
                reload={reload}
              />
            </Grid>
          </Grid>
        )
      }
    })
  }, [res.data, game, classes.checked, classes.cancel, reload])

  const data = useMemo(() => {
    const columns = game?.tableSetting as Array<keyof RowType> ?? []
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        code: {
          label: t('common.gameCode'),
          value: 'code',
          align: 'center'
        },
        name: {
          label: t('common.name'),
          value: 'name',
          align: 'center'
        },
        category: {
          label: t('common.class'),
          value: 'category',
          align: 'center'
        },
        agGciType: {
          label: 'GCI GameType',
          value: 'agGciType',
          align: 'center'
        },
        isShow: {
          label: t('common.showInGameHall'),
          value: 'isShow',
          align: 'center'
        },
        image: {
          label: t('common.image'),
          value: 'image',
          align: 'center'
        },
        functions: {
          label: t('common.function'),
          value: 'functions',
          align: 'center'
        }
      },
      [...columns, ...(writable ? ['functions'] as const : [])],
      rows,
      'id'
    )
  }, [rows, t, writable, game])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.externalGameChannel.encodePath
  })

  return (
    <ScrollablePaper marginX={6}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <CoreTable
            classes={tableClasses}
            showPagination
            page={page}
            onChangePage={handlePagination}
            data={data}
            total={res.total}
          />
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(ExternalGameChannelTable)
