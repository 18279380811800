import React, { createContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import NumberInput from '../../default/form/NumberInput'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { FormPropType, getValueFromChangeEvent, getValueFromCheckboxEvent } from '../../../utils/default/FormHelper'
import { createValidateNotEmpty } from '../../../utils/default/Validator'
import { createDefaultFormState, ValueGetter, FormValidation } from '../../../utils/default/FormHook'
import { InitialFormFunc } from '../../../utils/default/ComplexFlowHook'

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.error.main,
    whiteSpace: 'pre'
  }
}))

export interface EditPlayerLayerFormType {
  name: string
  register: string
  depositCount: string
  depositCash: string
  isManual: boolean
}

export const initialForm: InitialFormFunc<EditPlayerLayerFormType> = (defaultForm) => ({
  name: '',
  register: '0',
  depositCount: '0',
  depositCash: '0',
  isManual: false,
  ...defaultForm
})

const getValueFromEvent: ValueGetter<EditPlayerLayerFormType> = {
  name: getValueFromChangeEvent,
  register: getValueFromChangeEvent,
  depositCount: getValueFromChangeEvent,
  depositCash: getValueFromChangeEvent,
  isManual: getValueFromCheckboxEvent
}

interface PropTypes extends FormPropType<EditPlayerLayerFormType> {
  title: string
  okText: string
  onBack: () => void
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const moneyInputProps = {
  thousandSeparator: true,
  decimalScale: 0,
  allowNegative: false,
  fixedDecimalScale: true
}

const Button = React.memo(MuiButton)
const TextField = React.memo(MuiTextField)

const PlayerLayerForm: React.FC<PropTypes> = (props) => {
  const { title, okText, onBack, onSubmit, defaultValue } = props

  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { t } = useT()

  const validation = useMemo(() => {
    return {
      name: [
        {
          func: createValidateNotEmpty('name', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      register: [
        {
          func: createValidateNotEmpty('register', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      depositCount: [
        {
          func: createValidateNotEmpty('depositCount', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      depositCash: [
        {
          func: createValidateNotEmpty('depositCash', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      isManual: []
    } as FormValidation<EditPlayerLayerFormType>
  }, [t])

  return (
    <FormStateProvider
      context={FormContext}
      onSubmit={onSubmit}
      defaultValue={defaultValue}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={commonClasses.pinkTitleBar}
              >
                <Typography variant="h5">
                  {title}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} wrap="wrap">
                <Grid item xs={12} md={3}>
                  <FormField<EditPlayerLayerFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="name"
                    label={t('common.layerName')}
                    placeholder={t('placeholder.inputLayerName')}
                    fullWidth
                    required
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<EditPlayerLayerFormType, TextFieldProps>
                    context={FormContext}
                    component={NumberInput}
                    name="register"
                    label={t('common.registerDay')}
                    fullWidth
                    required
                    inputProps={moneyInputProps}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<EditPlayerLayerFormType, TextFieldProps>
                    context={FormContext}
                    component={NumberInput}
                    name="depositCount"
                    label={t('common.totalDepositCount')}
                    fullWidth
                    required
                    inputProps={moneyInputProps}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField<EditPlayerLayerFormType, TextFieldProps>
                    context={FormContext}
                    component={NumberInput}
                    name="depositCash"
                    label={t('common.totalDepositCash')}
                    fullWidth
                    required
                    inputProps={moneyInputProps}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} wrap="wrap">
                <Grid item>
                  <FormField<EditPlayerLayerFormType, OnOffCheckboxProps>
                    component={OnOffCheckbox}
                    context={FormContext}
                    name="isManual"
                    label={t('common.onlyManualAssign')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <span className={classes.text}>{t('common.playerLayerCreateTip')}</span>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    className={commonClasses.greyButton}
                    onClick={onBack}
                  >
                    {t('common.cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <FormSubmitButton
                    context={FormContext}
                    component={Button}
                    type="submit"
                    className={commonClasses.purpleGradualButton}
                  >
                    {okText}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(PlayerLayerForm)
