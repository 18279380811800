import { PermissionType } from '@golden/gdk-admin'
import TKeyType from '../../i18ns/admin/TKeyType'

export interface PermissionItem {
  name: TKeyType
  readable: PermissionType
  writable: Array<{
    name: TKeyType
    value: PermissionType
  }>
  children?: Array<Omit<PermissionItem, 'children'>>
}

const adminPermissionGroup: Array<{
  name: TKeyType
  items: PermissionItem[]
}> = [
  {
    name: 'adminPermission.group.forestage',
    items: [
      {
        name: 'page.announcement',
        readable: PermissionType.ANNOUNCEMENT_READ,
        writable: [{ name: 'common.edit', value: PermissionType.ANNOUNCEMENT }]
      },
      {
        name: 'page.agentAnnouncement',
        readable: PermissionType.AGENT_ANNOUNCEMENT_READ,
        writable: [{ name: 'common.toggleOff', value: PermissionType.AGENT_ANNOUNCEMENT }]
      },
      {
        name: 'page.notification',
        readable: PermissionType.NOTIFICATION_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.NOTIFICATION_SETTING }]
      },
      {
        name: 'page.announcementPersonal',
        readable: PermissionType.SEND_MESSAGE_READ,
        writable: [
          { name: 'common.edit', value: PermissionType.SEND_MESSAGE },
          { name: 'adminPermission.messageTemplateManagement', value: PermissionType.SEND_MESSAGE_TEMPLATE }
        ]
      },
      {
        name: 'page.sms',
        readable: PermissionType.SMS_RECORD_READ,
        writable: [
          { name: 'adminPermission.smsProviderManagement', value: PermissionType.SMS_PROVIDER },
          { name: 'common.resendShort', value: PermissionType.SMS_RECORD_RESEND }
        ]
      },
      {
        name: 'page.downloadLink',
        readable: PermissionType.FORWARDER_DOWNLOAD_LINK_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.FORWARDER_DOWNLOAD_LINK_SETTING }]
      },
      {
        name: 'page.jointVenture',
        readable: PermissionType.JOINT_VENTURE_LINK_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.JOINT_VENTURE_LINK_SETTING }]
      }
    ]
  },
  {
    name: 'adminPermission.group.externalGame',
    items: [
      {
        name: 'page.externalGame',
        readable: PermissionType.EXTERNAL_GAME_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.EXTERNAL_GAME_SETTING }]
      },
      {
        name: 'page.feedbackSetting',
        readable: PermissionType.FEEDBACK_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.FEEDBACK_SETTING }]
      },
      {
        name: 'page.channel',
        readable: PermissionType.CHANNEL_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.CHANNEL_SETTING }]
      }
    ]
  },
  {
    name: 'adminPermission.group.account',
    items: [
      {
        name: 'page.emperor',
        readable: PermissionType.EMPEROR_MANAGEMENT_READ,
        writable: [{ name: 'common.edit', value: PermissionType.EMPEROR_MANAGEMENT }]
      },
      {
        name: 'page.agentWallet',
        readable: PermissionType.AGENT_WALLET_MANAGEMENT_READ,
        writable: [{ name: 'common.create', value: PermissionType.AGENT_WALLET_MANAGEMENT }]
      },
      {
        name: 'page.agentOperationLog',
        readable: PermissionType.AGENT_OPERATION_LOG_READ,
        writable: []
      }
    ]
  },
  {
    name: 'adminPermission.group.player',
    items: [
      {
        name: 'page.ipHistory',
        readable: PermissionType.LOGIN_IP_SEARCH_READ,
        writable: []
      },
      {
        name: 'page.gameLoginHistory',
        readable: PermissionType.PLAYER_GAME_LOGIN_HISTORY_READ,
        writable: []
      },
      {
        name: 'page.ipMonitoring',
        readable: PermissionType.PLAYER_IP_MONITORING_HISTORY_READ,
        writable: []
      },
      {
        name: 'page.playerJumpShip',
        readable: PermissionType.PLAYER_JUMP_SHIP_READ,
        writable: [{ name: 'common.edit', value: PermissionType.PLAYER_JUMP_SHIP }]
      },
      {
        name: 'page.player',
        readable: PermissionType.PLAYER_MANAGEMENT_READ,
        writable: [],
        children: [
          {
            name: 'adminPermission.playerManagementChildren.info',
            readable: PermissionType.PLAYER_MANAGEMENT_INFO_READ,
            writable: [
              { name: 'adminPermission.editPlayerFmMemo', value: PermissionType.PLAYER_MANAGEMENT_INFO_FM_MEMO },
              { name: 'adminPermission.editPlayerRcMemo', value: PermissionType.PLAYER_MANAGEMENT_INFO_RC_MEMO },
              { name: 'adminPermission.editPlayerCsMemo', value: PermissionType.PLAYER_MANAGEMENT_INFO_CS_MEMO },
              { name: 'adminPermission.editPlayerPhone', value: PermissionType.PLAYER_MANAGEMENT_INFO_PHONE },
              { name: 'adminPermission.editPlayerNickname', value: PermissionType.PLAYER_MANAGEMENT_INFO_NICKNAME },
              { name: 'adminPermission.editPlayerEmail', value: PermissionType.PLAYER_MANAGEMENT_INFO_EMAIL },
              { name: 'adminPermission.unbindGoubao', value: PermissionType.PLAYER_MANAGEMENT_INFO_GOUBAO_UNBIND }
            ]
          },
          {
            name: 'adminPermission.playerManagementChildren.permission',
            readable: PermissionType.PLAYER_MANAGEMENT_PERMISSION_READ,
            writable: [{ name: 'common.edit', value: PermissionType.PLAYER_MANAGEMENT_PERMISSION }]
          },
          {
            name: 'adminPermission.playerManagementChildren.center',
            readable: PermissionType.PLAYER_MANAGEMENT_CENTER_READ,
            writable: [{ name: 'common.edit', value: PermissionType.PLAYER_MANAGEMENT_CENTER }]
          },
          {
            name: 'adminPermission.playerManagementChildren.debitCard',
            readable: PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD_READ,
            writable: [
              { name: 'common.edit', value: PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD },
              { name: 'common.bindDebitCard', value: PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD_BIND }
            ]
          },
          {
            name: 'adminPermission.playerManagementChildren.cryptoWallet',
            readable: PermissionType.PLAYER_MANAGEMENT_CRYPTO_WALLET_READ,
            writable: [{ name: 'common.delete', value: PermissionType.PLAYER_MANAGEMENT_CRYPTO_WALLET }]
          },
          {
            name: 'adminPermission.playerManagementChildren.transaction',
            readable: PermissionType.PLAYER_MANAGEMENT_TRANSACTION_READ,
            writable: []
          },
          {
            name: 'adminPermission.playerManagementChildren.wallet',
            readable: PermissionType.PLAYER_MANAGEMENT_WALLET_READ,
            writable: []
          }
        ]
      },
      {
        name: 'page.playerRank',
        readable: PermissionType.PLAYER_RANK_MANAGEMENT_READ,
        writable: [{ name: 'common.edit', value: PermissionType.PLAYER_RANK_MANAGEMENT }]
      },
      {
        name: 'page.playerLayer',
        readable: PermissionType.PLAYER_LAYER_MANAGEMENT_READ,
        writable: [
          { name: 'common.resetLayerStartAt', value: PermissionType.PLAYER_LAYER_STATISTIC_ZERO },
          { name: 'common.stratifyLayer', value: PermissionType.PLAYER_LAYER_RESET_LAYER },
          { name: 'common.setPlayerLayer', value: PermissionType.PLAYER_LAYER_SET_PLAYER },
          { name: 'adminPermission.editPlayerLayer', value: PermissionType.PLAYER_LAYER_MANAGEMENT }
        ]
      },
      {
        name: 'page.rankEffectiveBet',
        readable: PermissionType.PLAYER_RANK_EFFECTIVE_BET_READ,
        writable: [{ name: 'common.edit', value: PermissionType.PLAYER_RANK_EFFECTIVE_BET }]
      },
      {
        name: 'page.playerOperationLogs',
        readable: PermissionType.PLAYER_MANAGEMENT_PLAYER_LOG_READ,
        writable: []
      }
    ]
  },
  {
    name: 'adminPermission.group.adminReport',
    items: [
      {
        name: 'page.adminReportProfit',
        readable: PermissionType.COMPANY_PROFIT_RECORD_READ,
        writable: []
      },
      {
        name: 'page.playerReportProfit',
        readable: PermissionType.PROFIT_RECORD_READ,
        writable: [{ name: 'common.export', value: PermissionType.PROFIT_RECORD_EXPORT }]
      },
      {
        name: 'page.depositStatistic',
        readable: PermissionType.DEPOSIT_STATISTIC_RECORD_READ,
        writable: []
      },
      {
        name: 'page.newPeopleStatistic',
        readable: PermissionType.NEW_PEOPLE_STATISTIC_RECORD_READ,
        writable: []
      },
      {
        name: 'page.deviceStatistic',
        readable: PermissionType.DEVICE_STATISTIC_RECORD_READ,
        writable: []
      },
      {
        name: 'page.companyStatistic',
        readable: PermissionType.COMPANY_STATISTIC_RECORD_READ,
        writable: []
      },
      {
        name: 'page.inAndOut',
        readable: PermissionType.IN_AND_OUT_RECORD_READ,
        writable: []
      },
      {
        name: 'page.platformFeeStatistic',
        readable: PermissionType.PLATFORM_FEE_RECORD_READ,
        writable: []
      },
      {
        name: 'page.playerLeaderboard',
        readable: PermissionType.PLAYER_LEADERBOARD_READ,
        writable: []
      }
    ]
  },
  {
    name: 'adminPermission.group.playerReport',
    items: [
      {
        name: 'page.betHistory',
        readable: PermissionType.BET_RECORD_READ,
        writable: [{ name: 'common.export', value: PermissionType.BET_RECORD_EXPORT }],
        children: [
          {
            name: 'page.fetchTask',
            readable: PermissionType.BET_RECORD_FETCH_READ,
            writable: [{ name: 'common.rollback', value: PermissionType.BET_RECORD_FETCH }]
          }
        ]
      },
      {
        name: 'page.feedbackHistory',
        readable: PermissionType.FEEDBACK_HISTORY,
        writable: []
      },
      {
        name: 'page.transferHistory',
        readable: PermissionType.TRANSFER_RECORD_READ,
        writable: [
          { name: 'common.transfer', value: PermissionType.TRANSFER_RECORD_TRANSFER },
          { name: 'common.review', value: PermissionType.TRANSFER_RECORD_REVIEW }
        ]
      },
      {
        name: 'page.cashLog',
        readable: PermissionType.CASH_LOG_RECORD_READ,
        writable: []
      },
      {
        name: 'page.cashAccounting',
        readable: PermissionType.CASH_ACCOUNTING_READ,
        writable: []
      },
      {
        name: 'page.winlossAnalyze',
        readable: PermissionType.PLAYER_REPORT_WINLOSSANALYZE,
        writable: []
      }
    ]
  },
  {
    name: 'adminPermission.group.finance',
    items: [
      {
        name: 'page.financeParameter',
        readable: PermissionType.FINANCE_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.FINANCE_SETTING }]
      },
      {
        name: 'page.piggyBank',
        readable: PermissionType.PIGGY_BANK_MANAGEMENT_READ,
        writable: [{ name: 'common.edit', value: PermissionType.PIGGY_BANK_MANAGEMENT }]
      },
      {
        name: 'page.piggyWechat',
        readable: PermissionType.PIGGY_WECHAT_MANAGEMENT_READ,
        writable: [{ name: 'common.edit', value: PermissionType.PIGGY_WECHAT_MANAGEMENT }]
      },
      {
        name: 'page.piggyAlipay',
        readable: PermissionType.PIGGY_ALIPAY_MANAGEMENT_READ,
        writable: [{ name: 'common.edit', value: PermissionType.PIGGY_ALIPAY_MANAGEMENT }]
      },
      {
        name: 'page.bank',
        readable: PermissionType.BANK_SETTING_READ,
        writable: [
          { name: 'common.edit', value: PermissionType.BANK_SETTING },
          { name: 'common.thirdPartyCode', value: PermissionType.BANK_SETTING_CODE }
        ]
      },
      {
        name: 'page.courier',
        readable: PermissionType.FINANCE_FORWARDER_READ,
        writable: [{ name: 'common.edit', value: PermissionType.FINANCE_FORWARDER }]
      },
      {
        name: 'page.deposit',
        readable: PermissionType.THIRD_PARTY_PAYMENT_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.THIRD_PARTY_PAYMENT_SETTING }]
      },
      {
        name: 'page.withdrawal',
        readable: PermissionType.WITHDRAW_WAY_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.WITHDRAW_WAY_SETTING }]
      },
      {
        name: 'page.blacklist',
        readable: PermissionType.DEBIT_CARD_BLACKLIST_READ,
        writable: [{ name: 'common.edit', value: PermissionType.DEBIT_CARD_BLACKLIST }]
      },
      {
        name: 'page.internalWithdraw',
        readable: PermissionType.INTERNAL_WITHDRAW_READ,
        writable: [{ name: 'common.edit', value: PermissionType.INTERNAL_WITHDRAW }]
      }
    ]
  },
  {
    name: 'adminPermission.group.deposit',
    items: [
      {
        name: 'page.depositCrawler',
        readable: PermissionType.DEPOSIT_CRAWLER_READ,
        writable: [{ name: 'common.edit', value: PermissionType.DEPOSIT_CRAWLER }]
      },
      {
        name: 'page.depositSlip',
        readable: PermissionType.GOLDEN_DEPOSIT_MANAGEMENT_READ,
        writable: [{ name: 'common.review', value: PermissionType.GOLDEN_DEPOSIT_MANAGEMENT }]
      },
      {
        name: 'page.thirdPartyDepositSlip',
        readable: PermissionType.THIRD_PARTY_DEPOSIT_MANAGEMENT_READ,
        writable: [{ name: 'common.review', value: PermissionType.THIRD_PARTY_DEPOSIT_MANAGEMENT }]
      },
      {
        name: 'page.depositError',
        readable: PermissionType.DEPOSIT_ERROR_READ,
        writable: []
      }
    ]
  },
  {
    name: 'adminPermission.group.withdrawal',
    items: [
      {
        name: 'page.withdrawRCManage',
        readable: PermissionType.WITHDRAW_RC_SLIP_READ,
        writable: [
          { name: 'common.review', value: PermissionType.WITHDRAW_RC_SLIP },
          { name: 'common.export', value: PermissionType.WITHDRAW_RC_SLIP_EXPORT }
        ]
      },
      {
        name: 'page.withdrawFMManage',
        readable: PermissionType.WITHDRAW_FM_SLIP_READ,
        writable: [
          { name: 'common.review', value: PermissionType.WITHDRAW_FM_SLIP },
          { name: 'common.export', value: PermissionType.WITHDRAW_FM_SLIP_EXPORT }
        ]
      },
      {
        name: 'page.withdrawLimit',
        readable: PermissionType.WITHDRAW_LIMIT_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.WITHDRAW_LIMIT_SETTING }]
      },
      {
        name: 'page.withdrawAuto',
        readable: PermissionType.AUTO_WITHDRAW_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.AUTO_WITHDRAW_SETTING }]
      },
      {
        name: 'page.withdrawSetting',
        readable: PermissionType.WITHDRAW_TIP_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.WITHDRAW_TIP_SETTING }]
      }
    ]
  },
  {
    name: 'adminPermission.group.manual',
    items: [
      {
        name: 'page.manualDeposit',
        readable: PermissionType.MANUAL_DEPOSIT_READ,
        writable: [
          { name: 'common.edit', value: PermissionType.MANUAL_DEPOSIT },
          { name: 'common.export', value: PermissionType.MANUAL_DEPOSIT_EXPORT }
        ]
      },
      {
        name: 'page.manualWithdraw',
        readable: PermissionType.MANUAL_WITHDRAW_READ,
        writable: [
          { name: 'common.edit', value: PermissionType.MANUAL_WITHDRAW },
          { name: 'common.export', value: PermissionType.MANUAL_WITHDRAW_EXPORT }
        ]
      },
      {
        name: 'page.effectiveCashHistory',
        readable: PermissionType.EFFECTIVE_CASH_READ,
        writable: [{ name: 'common.edit', value: PermissionType.EFFECTIVE_CASH }]
      }
    ]
  },
  {
    name: 'adminPermission.group.activityManagement',
    items: [
      {
        name: 'page.activityManagement',
        readable: PermissionType.ACTIVITY_MANAGEMENT_READ,
        writable: [{ name: 'common.edit', value: PermissionType.ACTIVITY_MANAGEMENT }]
      },
      {
        name: 'page.activity',
        readable: PermissionType.ACTIVITY_RECORD_READ,
        writable: []
      },
      {
        name: 'page.activityDetail',
        readable: PermissionType.ACTIVITY_RECORD_DETAIL_READ,
        writable: []
      },
      {
        name: 'page.activityApplication',
        readable: PermissionType.ACTIVITY_APPLICATION_READ,
        writable: [
          { name: 'common.edit', value: PermissionType.ACTIVITY_APPLICATION },
          { name: 'common.export', value: PermissionType.ACTIVITY_APPLICATION_EXPORT }
        ]
      },
      {
        name: 'page.activityWallet',
        readable: PermissionType.ACTIVITY_WALLET_READ,
        writable: []
      }
    ]
  },
  {
    name: 'adminPermission.group.customerService',
    items: [
      {
        name: 'page.cs',
        readable: PermissionType.CUSTOMER_SERVICE_READ,
        writable: []
      },
      {
        name: 'adminPermission.csRecord',
        readable: PermissionType.CS_RECORD_READ,
        writable: []
      }
    ]
  },
  {
    name: 'adminPermission.group.system',
    items: [
      {
        name: 'page.platformMaintenance',
        readable: PermissionType.PLATFORM_MAINTENANCE_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.PLATFORM_MAINTENANCE_SETTING }]
      },
      {
        name: 'page.systemGoogle',
        readable: PermissionType.SYS_GOOGLE_AUTH_READ,
        writable: [{ name: 'common.edit', value: PermissionType.SYS_GOOGLE_AUTH }]
      },
      {
        name: 'page.ipWhitelist',
        readable: PermissionType.IP_WHITELIST_READ,
        writable: [{ name: 'common.edit', value: PermissionType.IP_WHITELIST }]
      }
    ]
  },
  {
    name: 'adminPermission.group.manager',
    items: [
      {
        name: 'page.accountManagement',
        readable: PermissionType.COMPANY_ACCOUNT_MANAGEMENT_READ,
        writable: [{ name: 'common.edit', value: PermissionType.COMPANY_ACCOUNT_MANAGEMENT }]
      },
      {
        name: 'page.roleManagement',
        readable: PermissionType.ROLE_GROUP_SETTING_READ,
        writable: [{ name: 'common.edit', value: PermissionType.ROLE_GROUP_SETTING }]
      },
      {
        name: 'page.managerLoginHistory',
        readable: PermissionType.LOGIN_SEARCH_READ,
        writable: [],
        children: [
          {
            name: 'adminPermission.managerLoginHistoryChildren.admin',
            readable: PermissionType.ADMIN_LOGIN_IP_SEARCH_READ,
            writable: []
          },
          {
            name: 'adminPermission.managerLoginHistoryChildren.agent',
            readable: PermissionType.AGENT_LOGIN_IP_SEARCH_READ,
            writable: []
          }
        ]
      }
    ]
  },
  {
    name: 'adminPermission.group.journal',
    items: [
      {
        name: 'page.operationHistory',
        readable: PermissionType.OPERATION_RECORD_READ,
        writable: []
      },
      {
        name: 'page.warningLog',
        readable: PermissionType.ALERT_RECORD_READ,
        writable: [],
        children: [
          {
            name: 'adminPermission.warningLog.depostiWayFullCashReached',
            readable: PermissionType.ALERT_RECORD_DEPOSIT_WAY_FULL_CASH_REACHED,
            writable: []
          },
          {
            name: 'adminPermission.warningLog.transferStatusNotMatch',
            readable: PermissionType.ALERT_RECORD_TRANSFER_STATUS_NOT_MATCH,
            writable: []
          },
          {
            name: 'adminPermission.warningLog.noTransferLogInThirdParty',
            readable: PermissionType.ALERT_RECORD_NO_TRANSFER_LOG_IN_THIRD_PARTY,
            writable: []
          },
          {
            name: 'adminPermission.warningLog.betRevenue3W',
            readable: PermissionType.ALERT_RECORD_BET_REVENUE_3W,
            writable: []
          },
          {
            name: 'adminPermission.warningLog.withdrawRevenue3W',
            readable: PermissionType.ALERT_RECORD_WITHDRAW_REVENUE_3W,
            writable: []
          },
          {
            name: 'adminPermission.warningLog.realNameDuplicate',
            readable: PermissionType.ALERT_RECORD_REAL_NAME_DUPLICATE,
            writable: []
          },
          {
            name: 'adminPermission.warningLog.rcWarnedUserGameLogin',
            readable: PermissionType.ALERT_RECORD_RC_WARNED_USER_GAME_LOGIN,
            writable: []
          },
          {
            name: 'adminPermission.warningLog.trcBind',
            readable: PermissionType.ALERT_RECORD_TRC_BIND,
            writable: []
          },
          {
            name: 'adminPermission.warningLog.walletDuplicate',
            readable: PermissionType.ALERT_RECORD_WALLET_DUPLICATE,
            writable: []
          }
        ]
      }
    ]
  },
  {
    name: 'adminPermission.group.complain',
    items: [
      {
        name: 'page.complain',
        readable: PermissionType.COMPLAIN_READ,
        writable: [{ name: 'common.edit', value: PermissionType.COMPLAIN }]
      }
    ]
  }
]

export default adminPermissionGroup
