import React, { createContext, useMemo, useContext, useCallback } from 'react'
import { getTime, subDays, startOfDay, endOfDay } from 'date-fns'
import { omitBy, isUndefined } from '@golden/utils'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import { SearchToRequestFunc, useRequestFromSearch, InitialFormFunc, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, getTimeFromDateInputValue } from '../../../utils/default/FormHelper'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { PlatformFeeDailyRecordQuery } from '@golden/gdk-admin'
import MuiButton from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { ValueGetter, createDefaultFormState, FormValidation } from '../../../utils/default/FormHook'
import allRoute from '../route/route'
import { createValidateStartAtWithEndAt } from '../../../utils/default/Validator'
import { setLastMonth, createShouldDisableDate, setThisMonth, setYesterday } from '../../../utils/default/TimeHelper'
import { useChecker } from '../../../utils/admin/AdminRouteHook'

export interface PlatformFeeFormType {
  time: DateInputValue
}

export const searchToRequest: SearchToRequestFunc<PlatformFeeDailyRecordQuery> = (search) => {
  const lastDays = getTime(subDays(startOfDay(new Date()), 75))
  const endOfToday = getTime(endOfDay(new Date()))
  const converted = {
    ...search,
    start_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, lastDays, endOfToday)
    )(search.start_at),
    end_at: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, lastDays, endOfToday)
    )(search.end_at)
  } as PlatformFeeDailyRecordQuery
  if (converted.end_at < converted.start_at) throw new Error('The end time can\'t exceed the start time')
  return omitBy(converted, isUndefined) as PlatformFeeDailyRecordQuery
}

export const initialForm: InitialFormFunc<PlatformFeeFormType> = (defaultForm) => ({
  time: {
    start: startOfDay(subDays(new Date(), 2)),
    end: endOfDay(subDays(new Date(), 2))
  },
  ...defaultForm
})

const formToRequest = (form: PlatformFeeFormType): PlatformFeeDailyRecordQuery => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    start_at: getTime(form.time.start!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    end_at: getTime(form.time.end!)
  }
}

const getValueFromEvent: ValueGetter<PlatformFeeFormType> = {
  time: getTimeFromDateInputValue
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const Button = React.memo(MuiButton)

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const lastDays = subDays(startOfDay(new Date()), 75)
    const today = endOfDay(new Date())
    return createShouldDisableDate(value.time.start, value.time.end, lastDays, today)
  }, [value.time])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate
  }), [shouldDisableStartDate, t])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate
  }), [shouldDisableEndDate, t])

  const tools = useMemo(() => {
    return [
      {
        label: t('common.yesterday'),
        change: setYesterday
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth
      },
      {
        label: t('common.lastMonth'),
        change: setLastMonth
      }
    ]
  }, [t])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])
  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      onlyDate
    />
  )
})

interface PropTypes {
  reload: () => void
}

const AdminReportPlatformFeeForm: React.FC<PropTypes> = (props) => {
  const { reload } = props
  const classes = useCommonStyles()
  const { t } = useT()
  const writable = useChecker()
  const request = useRequestFromSearch({ searchToRequest })
  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        time: {
          start: new Date(request.start_at),
          end: new Date(request.end_at)
        }
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoute.adminReportPlatformFee.encodePath
  })

  const validation = useMemo(() => ({
    time: [
      {
        func: createValidateStartAtWithEndAt('time', t),
        when: ['change', 'beforeClickSubmit']
      }
    ]
  } as FormValidation<PlatformFeeFormType>), [t])

  return (
    <React.Fragment>
      <Paper>
        <Box
          paddingY={1.25}
          paddingX={2}
          className={classes.pinkTitleBar}
        >
          <Typography variant="h5">
            {t('common.platformFeeStatistic')}
          </Typography>
        </Box>
        <Box padding={4}>
          <FormStateProvider
            context={FormContext}
            defaultValue={defaultForm}
            onSubmit={handleSubmit}
            validation={validation}
            getValueFromEvent={getValueFromEvent}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <DateInput />
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Grid item>
                    <FormSubmitButton
                      component={Button}
                      context={FormContext}
                      type="submit"
                      className={classes.purpleGradualButton}
                    >
                      {t('common.search')}
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormStateProvider>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default React.memo(AdminReportPlatformFeeForm)
