import { Alert, AlertInfo } from '@golden/gdk-admin'
import depositTypeName from '../../constants/default/depositTypeName'
import { StoreGame, getGameName } from '../default/PlatformHelper'
import TKeyType from '../../i18ns/admin/TKeyType'
import { TOptions } from 'i18next'

export const getSnackbarMessage = (
  info: AlertInfo,
  subject: Alert['subject'],
  t: (key: TKeyType, options?: TOptions) => string
): string => {
  const depositName = subject.deposit_method ? t(depositTypeName[subject.deposit_method]) : ''
  const dictionary: { [key in AlertInfo]: string } = {
    [AlertInfo.DEPOSIT_WAY_FULL_CASH_REACHED]: `${subject.forwarder_name ?? ''} / ${depositName} ${t('common.willFull')}`,
    [AlertInfo.TRANSFER_STATUS_NOT_MATCH]: t('common.transferNotMatchWarning'),
    [AlertInfo.NO_TRANSFER_LOG_IN_THIRD_PARTY]: t('common.noTransferLogInThirdPartyWarning'),
    [AlertInfo.BET_REVENUE_3W]: t('common.betRevenue3w'),
    [AlertInfo.TRC_BIND]: t('common.trcBind'),
    [AlertInfo.REAL_NAME_DUPLICATE]: t('common.realNameDuplicateWarning'),
    [AlertInfo.RC_WARNED_USER_GAME_LOGIN]: t('common.rcWarnedUserGameLogin'),
    [AlertInfo.WITHDRAW_REVENUE_3W]: t('common.withdrawRevenue3W'),
    [AlertInfo.WALLET_DUPLICATE]: t('common.walletDuplicateWarning')
  }
  return dictionary[info]
}

export const getInfo = (
  info: AlertInfo,
  subject: Alert['subject'],
  t: (key: TKeyType, options?: TOptions) => string,
  games: StoreGame[]
): string => {
  const dictionary: { [key in AlertInfo]: string } = {
    [AlertInfo.DEPOSIT_WAY_FULL_CASH_REACHED]: t('common.willFullCash', { cash: subject.accumulated_cash }),
    [AlertInfo.TRANSFER_STATUS_NOT_MATCH]: t('common.transferStatusNotMatch'),
    [AlertInfo.NO_TRANSFER_LOG_IN_THIRD_PARTY]: t('common.noTransferLogInThirdParty'),
    [AlertInfo.BET_REVENUE_3W]: t('common.betRevenue3wWarning'),
    [AlertInfo.TRC_BIND]: '',
    [AlertInfo.REAL_NAME_DUPLICATE]: t('common.realNameDuplicate', {
      accounts: subject.duplicate_accounts?.length ? subject.duplicate_accounts.join('、') : ''
    }),
    [AlertInfo.RC_WARNED_USER_GAME_LOGIN]: t('common.rcWarnedUserGameLoginWarning', { gameName: (subject.game_id ? getGameName(subject.game_id, games) : '') }),
    [AlertInfo.WITHDRAW_REVENUE_3W]: '',
    [AlertInfo.WALLET_DUPLICATE]: t('common.walletDuplicate', {
      accounts: subject.duplicate_accounts?.length ? subject.duplicate_accounts.join('、') : ''
    })
  }
  return dictionary[info]
}
