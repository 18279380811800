import React, { useState, useMemo, createContext, Dispatch, SetStateAction } from 'react'
import { CashAccountingBetHistoryStatistic, GameCategoryType, GameStatus, GameType, RecordSearchTimeType } from '@golden/gdk-admin'
import { BcMath } from '@golden/bcmath'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { searchToRequest } from './PlayerReportCashAccountingForm'
import CoreTable from '../../../default/present/CoreTable'
import PointsCell from '../../../default/present/PointsCell'
import LoadingAndErrorFrame from '../../../default/frames/LoadingAndErrorFrame'
import ScrollablePaper from '../../../default/present/ScrollablePaper'
import { useRequestFromSearch, useGetDataByPayload } from '../../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../../utils/default/PageFlowHook'
import useGDK from '../../../../providers/admin/gdk/useGDK'
import { createTableData, formatMoney } from '../../../../utils/default/TableHelper'
import useT from '../../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../../utils/admin/StyleHook'
import useGDKStore from '../../../../providers/admin/gdk/useGDKStore'
import StateProvider from '../../../../providers/default/StateProvider'
import allRoute from '../../route/route'
import { mergeArray } from '@golden/utils'

interface BetHistoryStatisticDetailRowType {
  id: number
  game: string
  cashWinning: string
  buybackAmount: string
  feedback: string
  betCash: string
  revenue: React.ReactElement
  donate: string
  game_category: GameCategoryType
  game_id: GameType
  hasRecord: boolean
}

interface BetHistoryStatisticRowType {
  id: number
  category: React.ReactElement | string
  cashWinning: React.ReactElement | string
  buybackAmount: string
  feedback: string
  betCash: React.ReactElement | string
  revenue: React.ReactElement
  donate: string
  details: BetHistoryStatisticDetailRowType[]
}

const StateContext = createContext<[
  number[],
  Dispatch<SetStateAction<number[]>>
]>([
  [],
  () => {}
])

const BetHistoryStatisTicDetailPanel: React.FC<{ row: Map<string, any> }> = React.memo((props) => {
  const { row } = props
  const commonClasses = useCommonStyles()
  const payload = useRequestFromSearch({ searchToRequest })
  return (
    <Table>
      <TableBody>
        {(row.get('details') as BetHistoryStatisticDetailRowType[])?.map((item) => (
          <TableRow key={item.id} className={commonClasses.tableRow}>
            <TableCell align="left" width={100}>
              <Box paddingLeft={2.5}>{item.game}</Box>
            </TableCell>
            <TableCell align="center" width={100}>
              {
                item.hasRecord
                  ? (<Link
                    className={commonClasses.anchor}
                    to={allRoute.playerReportBet.encodePath({
                      search: {
                        account: payload?.account,
                        search_type: RecordSearchTimeType.SETTLED_AT,
                        game_category: item.game_category,
                        game_id: item.game_id,
                        start_at: payload?.start_at,
                        end_at: payload?.end_at,
                        page: 1
                      },
                      param: {}
                    })}
                    target="_blank"
                  >
                    {formatMoney(item.cashWinning)}
                  </Link>)
                  : formatMoney(item.cashWinning)
              }
            </TableCell>
            <TableCell align="center" width={100}>{item.buybackAmount}</TableCell>
            <TableCell align="center" width={100}>{item.feedback}</TableCell>
            <TableCell align="center" width={100}>
              {
                BcMath.base(item.betCash).comp('0') > 0
                  ? (<Link
                    className={commonClasses.anchor}
                    to={allRoute.playerReportBet.encodePath({
                      search: {
                        account: payload?.account,
                        search_type: RecordSearchTimeType.CREATED_AT,
                        game_category: item.game_category,
                        game_id: item.game_id,
                        start_at: payload?.start_at,
                        end_at: payload?.end_at,
                        page: 1
                      },
                      param: {}
                    })}
                    target="_blank"
                  >
                    {formatMoney(item.betCash)}
                  </Link>)
                  : formatMoney(item.betCash)
              }
            </TableCell>
            <TableCell align="center" width={100}>{item.revenue}</TableCell>
            <TableCell align="center" width={100}>{item.donate}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

const BetHistoryStatisticTable: React.FC = React.memo(() => {
  const commonClasses = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const categories = useGDKStore.platform.categories()
  const aliveGames = useGDKStore.platform.aliveGames()
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const [list, setList] = useState<CashAccountingBetHistoryStatistic[]>([])
  const pageFlow = usePageFlow()
  const payload = useRequestFromSearch({ searchToRequest })
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: payload!,
    gdkFunc: (payload) => gdk.history.getCashAccountingBetHistoryStatistic(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: CashAccountingBetHistoryStatistic[]) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: payload !== undefined
  })

  const rows: BetHistoryStatisticRowType[] = useMemo(() => categories.map((category) => {
    const id = category.instance.id
    const sublist = mergeArray(category.games, list, (game, item) => game.id === item.game_id)
      .map(([game, item]) => ({ ...item, nameWithStatus: game.nameWithStatus }))
    const totalCashWinning = sublist.reduce((pre, cur) => BcMath.base(pre).add(cur.cash_winning).get(), '0')
    const totalBetCash = sublist.reduce((pre, cur) => BcMath.base(pre).add(cur.bet_cash).get(), '0')
    const hasRecord = sublist.some(item => item.has_record)

    return {
      id,
      category: sublist.length > 0
        ? (
          <StateContext.Consumer>
            {([, setSelects]) => (
              <span
                className={commonClasses.anchor}
                onClick={() => {
                  setSelects((selects) => {
                    if (selects.includes(id)) {
                      return selects.filter((select) => select !== id)
                    }
                    return selects.concat(id)
                  })
                }}
              >
                {category.instance.name}
              </span>
            )}
          </StateContext.Consumer>
          )
        : category.instance.name,
      cashWinning: hasRecord
        ? (<Link
          className={commonClasses.anchor}
          to={allRoute.playerReportBet.encodePath({
            search: {
              account: payload?.account,
              search_type: RecordSearchTimeType.SETTLED_AT,
              game_category: id,
              start_at: payload?.start_at,
              end_at: payload?.end_at,
              page: 1
            },
            param: {}
          })}
          target="_blank"
        >
          {formatMoney(totalCashWinning)}
        </Link>)
        : formatMoney(totalCashWinning),
      buybackAmount: formatMoney(sublist.reduce((pre, cur) => BcMath.base(pre).add(cur.buyback_amount).get(), '0')),
      feedback: formatMoney(sublist.reduce((pre, cur) => BcMath.base(pre).add(cur.feedback).get(), '0')),
      betCash: BcMath.base(totalBetCash).comp('0') > 0
        ? (<Link
          className={commonClasses.anchor}
          to={allRoute.playerReportBet.encodePath({
            search: {
              account: payload?.account,
              search_type: RecordSearchTimeType.CREATED_AT,
              game_category: id,
              start_at: payload?.start_at,
              end_at: payload?.end_at,
              page: 1
            },
            param: {}
          })}
          target="_blank"
        >
          {formatMoney(totalBetCash)}
        </Link>)
        : formatMoney(totalBetCash),
      revenue: (<PointsCell showSign points={sublist.reduce((pre, cur) => BcMath.base(pre).add(cur.revenue).get(), '0')}/>),
      donate: formatMoney(sublist.reduce((pre, cur) => BcMath.base(pre).add(cur.donate).get(), '0')),
      details: sublist.map((item, i) => ({
        id: i,
        game: item.nameWithStatus,
        cashWinning: item.cash_winning, // 後面要做運算，不用格式化
        buybackAmount: formatMoney(item.buyback_amount),
        feedback: formatMoney(item.feedback),
        betCash: item.bet_cash, // 後面要做運算，不用格式化
        revenue: (<PointsCell showSign points={item.revenue} />),
        donate: formatMoney(item.donate),
        game_category: item.game_category,
        game_id: item.game_id,
        hasRecord: item.has_record
      }))
        .filter((item) => (aliveGames.some((game) => (game.id === item.game_id)) || Number(item.betCash) !== 0))
    }
  }), [categories, list, commonClasses.anchor, payload?.account, payload?.start_at, payload?.end_at])

  const data = useMemo(() => {
    return createTableData<BetHistoryStatisticRowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        category: {
          label: t('common.gameType'),
          value: 'category',
          align: 'left',
          width: 100
        },
        cashWinning: {
          label: t('common.payedCash'),
          value: 'cashWinning',
          align: 'center',
          width: 100
        },
        buybackAmount: {
          label: t('common.totalBuyback'),
          value: 'buybackAmount',
          align: 'center',
          width: 100
        },
        feedback: {
          label: t('common.allFeedback'),
          value: 'feedback',
          align: 'center',
          width: 100
        },
        betCash: {
          label: t('common.allBetCash'),
          value: 'betCash',
          align: 'center',
          width: 100
        },
        revenue: {
          label: t('common.gameRevenue'),
          value: 'revenue',
          align: 'center',
          width: 100
        },
        donate: {
          label: t('common.donate'),
          value: 'donate',
          align: 'center',
          width: 100
        },
        details: {
          label: '',
          value: 'details'
        }
      },
      [
        'category',
        'cashWinning',
        'buybackAmount',
        'feedback',
        'betCash',
        'revenue',
        'donate'
      ],
      rows,
      'id'
    )
  }, [rows, t])

  if (payload === undefined) return null
  return (
    <ScrollablePaper marginX={5}>
      <Box padding={4}>
        <LoadingAndErrorFrame { ...pageFlow.status }>
          <StateProvider
            context={StateContext}
            defaultValue={[]}
          >
            <StateContext.Consumer>
              {([selects]) => (
                <CoreTable
                  classes={tableClasses}
                  data={data}
                  total={rows.length}
                  isSelected
                  selected={selects}
                  collapse={BetHistoryStatisTicDetailPanel}
                />
              )}
            </StateContext.Consumer>
          </StateProvider>
          <Box paddingTop={1}>
            <Typography color="error" className={commonClasses.pre}>{t('common.cashAccountingTip')}</Typography>
          </Box>
        </LoadingAndErrorFrame>
      </Box>
    </ScrollablePaper>
  )
})

export default React.memo(BetHistoryStatisticTable)
