import React, { createContext, Dispatch, SetStateAction, useMemo, useState, useCallback } from 'react'
import { AgentType, ActivityOverviewQuery, ActivityAgentOverview, PaginationRes, PaginationReq } from '@golden/gdk-admin'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import CoreTable from '../../default/present/CoreTable'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import agentTypeName from '../../../constants/default/agentTypeName'
import { getTitleOfNextLayer } from '../../../utils/default/AgentAccountHelper'
import { formatCount, formatMoney, createTableData, ColumnCollection, createDefaultPaginationData } from '../../../utils/default/TableHelper'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { useRequestFromSearch, useGetDataByPayload } from '../../../utils/default/ComplexFlowHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { searchToRequest } from './ActivityRecordAgentLayerForm'

interface RowType {
  id: number
  account: string
  cash_count: string
  user_count: string
  cash_sum: string
}

interface ChildRowType {
  id: number
  account: JSX.Element | string
  cash_count: string
  user_count: string
  cash_sum: string
}

const MemoPopoverContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const ActivityRecordAgentLayerTable: React.FC = () => {
  const classes = useCommonStyles()
  const gdk = useGDK()
  const { t } = useT()

  const [showAllAgentData, setShowAllAgentData] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)

  const request = useRequestFromSearch({ searchToRequest })

  const pageFlow = usePageFlow(true, false)
  const [info, setInfo] = useState<PaginationRes<ActivityAgentOverview[]> & ActivityAgentOverview>({
    ...createDefaultPaginationData([]),
    id: 0,
    cash_count: 0,
    user_count: 0,
    cash_sum: '0',
    account: '',
    type: 0
  })

  const payload = useMemo(() => {
    return {
      ...request,
      page,
      is_all_data: showAllAgentData
    }
  }, [request, page, showAllAgentData])

  useGetDataByPayload({
    payload: payload as ActivityOverviewQuery & PaginationReq,
    gdkFunc: (payload) => gdk.activity.getActivityRecordGoldenOverview(payload),
    gdkFuncDependencies: [gdk, showAllAgentData],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<ActivityAgentOverview[]> & ActivityAgentOverview) => {
      setInfo(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const columnData: ColumnCollection<RowType> = useMemo(() => {
    return {
      id: {
        label: '',
        value: 'id'
      },
      account: {
        label: t(agentTypeName[info?.type ? info.type as AgentType : AgentType.EMPEROR]),
        value: 'account',
        align: 'center'
      },
      cash_count: {
        label: t('common.totalBonusTimesCount'),
        value: 'cash_count',
        align: 'center'
      },
      user_count: {
        label: t('common.totalBonusPersonCount'),
        value: 'user_count',
        align: 'center'
      },
      cash_sum: {
        label: t('common.totalActivityBonus'),
        value: 'cash_sum',
        align: 'center'
      }
    }
  }, [t, info])

  const mainRows: RowType[] = useMemo(() => {
    return [{
      id: info?.id,
      account: info?.account,
      cash_count: formatCount(info?.cash_count),
      user_count: formatCount(info?.user_count),
      cash_sum: formatMoney(info?.cash_sum)
    }]
  }, [info])

  const mainData = useMemo(() => {
    return createTableData(
      columnData,
      [
        'account',
        'cash_count',
        'user_count',
        'cash_sum'
      ],
      mainRows,
      'id'
    )
  }, [mainRows, columnData])

  const childRows = useMemo(() => {
    return info.data.map((child) => ({
      id: child.id,
      account: (<span>{child.account}</span>),
      cash_count: formatCount(child.cash_count),
      user_count: formatCount(child.user_count),
      cash_sum: formatMoney(child.cash_sum)
    })).filter((item) => {
      if (showAllAgentData) return true
      return (item.cash_count !== formatCount(0))
    }) as ChildRowType[]
  }, [info, showAllAgentData])

  const subData = useMemo(() => {
    return createTableData(
      {
        ...columnData,
        account: {
          label: t(getTitleOfNextLayer(info?.type ? info.type as AgentType : AgentType.EMPEROR)),
          value: 'account',
          align: 'center'
        }
      },
      [
        'account',
        'cash_count',
        'user_count',
        'cash_sum'
      ],
      childRows,
      'id'
    )
  }, [childRows, columnData, t, info])

  const tableClasses = useMemo(() => {
    return {
      head: classes.greyTableHead,
      cellHead: classes.tableCellHead,
      row: classes.tableRow
    }
  }, [classes])

  const handlePagination = useCallback((_, page) => setPage(page), [])

  return (
    <ScrollablePaper marginX={6}>
      <Box padding={6}>
        <Grid container spacing={3} direction="row" alignItems="stretch">
          <MemoPopoverWithContext memoPopoverContext={MemoPopoverContext}>
            <Grid item xs={12}>
              <Box width="100%">
                <LoadingAndErrorFrame { ...pageFlow.status }>
                  <Box paddingBottom={6} width="100%">
                    <CoreTable
                      classes={tableClasses}
                      data={mainData}
                      total={1}
                    />
                  </Box>
                  <CoreTable
                    classes={tableClasses}
                    data={subData}
                    total={info.total}
                    showPagination
                    page={page}
                    onChangePage={handlePagination}
                  />
                </LoadingAndErrorFrame>
              </Box>
            </Grid>
          </MemoPopoverWithContext>
        </Grid>
      </Box>
    </ScrollablePaper>
  )
}

export default React.memo(ActivityRecordAgentLayerTable)
