import React, { createContext, useMemo, useContext, useCallback } from 'react'
import { PaginationReq, AdminPlayerListQuery, PlayerSearchTimeType, PlayerRankType, PlayerStatusType, FinanceRoleType, GameType, PlayerStates, BindAccountType, PMVersionType } from '@golden/gdk-admin'
import { omitBy, isUndefined } from '@golden/utils'
import { endOfDay, subYears, startOfYear, getTime } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import DateInputBase, { DateInputValue } from '../../default/form/DateInput'
import DropDown, { PropTypes as DropDownProps } from '../../default/form/DropDown'
import MultipleSelector, { PropTypes as MultipleSelectorPropTypes } from '../../default/form/MultipleSelector'
import OnOffCheckbox, { PropTypes as OnOffCheckboxProps } from '../../default/form/OnOffCheckbox'
import FormStateProvider from '../../default/form/FormStateProvider'
import FormField from '../../default/form/FormField'
import FormSubmitButton from '../../default/form/FormSubmitButton'
import { SearchToRequestFunc, InitialFormFunc, useRequestFromSearch, useChangeUrlSubmit } from '../../../utils/default/ComplexFlowHook'
import { pipe, guaranteeNotUndefined, parseInt, guaranteeBetween, acceptUndefined, getTimeFromDateInputValue, getValueFromChangeEvent, guaranteeBeKey, convertEmptyToUndefined, parseArray, getValueFromValue, getValueFromCheckboxEvent } from '../../../utils/default/FormHelper'
import playerRankName from '../../../constants/default/playerRankName'
import playerStatusName from '../../../constants/default/playerStatusName'
import playerSearchTimeName from '../../../constants/admin/playerSearchTimeName'
import { ValueGetter, createDefaultFormState, FormValidation, ChangedFormGetter } from '../../../utils/default/FormHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { createShouldDisableDate, setToday, setYesterday, setThisMonth } from '../../../utils/default/TimeHelper'
import allRoutes from '../route/route'
import { createValidateStartAtWithEndAt, createCorrectResult, createErrorResult } from '../../../utils/default/Validator'
import useGDKStore from '../../../providers/admin/gdk/useGDKStore'
import stateTypeName from '../../../constants/admin/stateTypeName'
import financeRoleTypeName from '../../../constants/admin/financeRoleTypeName'
import GameIdInput, { PropTypes as GameIdProps } from '../GameIdInput'
import { bindAccount } from '../../../constants/admin/bindAccountName'
import pmVersionName from '../../../constants/default/pmVersionName'

export type PlayerRequest = PaginationReq & AdminPlayerListQuery

export interface PlayerFormType {
  search_time_type: PlayerSearchTimeType | '--'
  time: DateInputValue
  account: string
  staff_account: string
  rank: '--' | PlayerRankType
  layer_id: '--' | number
  status: '--' | PlayerStatusType
  states: '--' | PlayerStates
  wallet_protocol: '--' | BindAccountType
  pm_version_switch: '--' | PMVersionType
  finance_roles: FinanceRoleType[]
  forbidden_games: GameType[]
  fm_warned: boolean
  rc_warned: boolean
  register_ip: string
  real_name: string
  bank_account: string
  phone: string
}

export const searchToRequest: SearchToRequestFunc<PlayerRequest> = (search) => {
  const fiveYearsAgo = getTime(startOfYear(subYears(new Date(), 5)))
  const endOfToday = getTime(endOfDay(new Date()))
  const convert = {
    ...search,
    page: pipe(
      guaranteeNotUndefined,
      parseInt,
      (value) => guaranteeBetween(value, 1, Number.MAX_SAFE_INTEGER)
    )(search.page),
    search_time_type: acceptUndefined(search.search_time_type, pipe(
      (value) => guaranteeBeKey(value, Object.keys(playerSearchTimeName)),
      parseInt
    )),
    start_at: acceptUndefined(search.start_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    end_at: acceptUndefined(search.end_at, pipe(
      parseInt,
      (value) => guaranteeBetween(value, fiveYearsAgo, endOfToday)
    )),
    rank: acceptUndefined(search.rank, pipe(
      (value) => guaranteeBeKey(value, Object.keys(playerRankName))
    )),
    layer_id: acceptUndefined(search.layer_id, parseInt),
    status: acceptUndefined(search.status, pipe(
      (value) => guaranteeBeKey(value, Object.keys(playerStatusName)),
      parseInt
    )),
    states: acceptUndefined(search.states, pipe(
      parseArray,
      (values) => values.map((item: string) => item as PlayerStates)
    )),
    finance_roles: acceptUndefined(search.finance_roles, pipe(
      parseArray,
      (values) => values.map((item: string) => item as FinanceRoleType)
    )),
    forbidden_games: acceptUndefined(search.forbidden_games, pipe(
      parseArray,
      (values) => values.map((item: string) => Number(item) as GameType)
    )),
    fm_warned: acceptUndefined(search.fm_warned, parseInt),
    rc_warned: acceptUndefined(search.rc_warned, parseInt)
  } as PlayerRequest
  return omitBy(convert, isUndefined) as PlayerRequest
}

export const initialForm: InitialFormFunc<PlayerFormType> = (defaultForm) => ({
  time: {
    start: null,
    end: null
  },
  search_time_type: '--',
  account: '',
  staff_account: '',
  rank: '--',
  layer_id: '--',
  status: '--',
  states: '--',
  wallet_protocol: '--',
  pm_version_switch: '--',
  finance_roles: [],
  forbidden_games: [],
  fm_warned: false,
  rc_warned: false,
  register_ip: '',
  real_name: '',
  bank_account: '',
  phone: '',
  ...defaultForm
})

const formToRequest = (form: PlayerFormType): PlayerRequest => {
  const converted = {
    search_time_type: form.search_time_type === '--' ? undefined : form.search_time_type,
    start_at: form.time.start === null ? undefined : getTime(form.time.start),
    end_at: form.time.end === null ? undefined : getTime(form.time.end),
    account: convertEmptyToUndefined(form.account),
    staff_account: convertEmptyToUndefined(form.staff_account),
    rank: form.rank === '--' ? undefined : form.rank,
    layer_id: form.layer_id === '--' ? undefined : form.layer_id,
    status: form.status === '--' ? undefined : form.status,
    states: form.states === '--' ? undefined : [form.states],
    wallet_protocol: form.wallet_protocol === '--' ? undefined : [form.wallet_protocol],
    pm_version_switch: form.pm_version_switch === '--' ? undefined : form.pm_version_switch,
    finance_roles: form.finance_roles.length ? form.finance_roles : undefined,
    forbidden_games: form.forbidden_games.length ? form.forbidden_games : undefined,
    fm_warned: form.fm_warned ? 1 : undefined,
    rc_warned: form.rc_warned ? 1 : undefined,
    register_ip: convertEmptyToUndefined(form.register_ip),
    real_name: convertEmptyToUndefined(form.real_name),
    bank_account: convertEmptyToUndefined(form.bank_account),
    phone: convertEmptyToUndefined(form.phone),
    page: 1
  } as PlayerRequest
  return omitBy(converted, isUndefined) as PlayerRequest
}

const getValueFromEvent: ValueGetter<PlayerFormType> = {
  search_time_type: getValueFromChangeEvent,
  time: getTimeFromDateInputValue,
  account: getValueFromChangeEvent,
  staff_account: getValueFromChangeEvent,
  rank: getValueFromChangeEvent,
  layer_id: getValueFromChangeEvent,
  status: getValueFromChangeEvent,
  states: getValueFromChangeEvent,
  wallet_protocol: getValueFromChangeEvent,
  pm_version_switch: getValueFromChangeEvent,
  finance_roles: getValueFromValue,
  forbidden_games: getValueFromValue,
  fm_warned: getValueFromCheckboxEvent,
  rc_warned: getValueFromCheckboxEvent,
  register_ip: getValueFromChangeEvent,
  real_name: getValueFromChangeEvent,
  bank_account: getValueFromChangeEvent,
  phone: getValueFromChangeEvent
}

const getChangedForm: ChangedFormGetter<PlayerFormType> = {
  search_time_type: (value, form) => ({ ...form, search_time_type: value, time: value === '--' ? { start: null, end: null } : form.time }),
  states: (value, form) => ({ ...form, states: value, finance_roles: [] })
}

const FormContext = createContext(createDefaultFormState(initialForm()))

const TextField = React.memo(MuiTextField)
const Button = React.memo(MuiButton)

const SearchDropDown: React.FC = React.memo(() => {
  const { t } = useT()
  const { value, handleChange, handleOther, error } = useContext(FormContext)
  const searchTimeOptions = useMemo(() => {
    return [{ name: t('common.chooseSearchDate'), value: '--' }].concat(
      Object.keys(playerSearchTimeName)
        .map((key) => Number(key) as PlayerSearchTimeType)
        .map((key) => ({ name: t(playerSearchTimeName[key]), value: key })) as any
    )
  }, [t])
  return (
    <Grid item xs={12} md={6} lg={2}>
      <DropDown
        value={value.search_time_type}
        onChange={handleChange('search_time_type')}
        onBlur={handleOther('blur', 'search_time_type')}
        options={searchTimeOptions}
        label={t('common.searchDate')}
        fullWidth
        error={error.search_time_type !== null}
        helperText={error.search_time_type ?? ''}
      />
    </Grid>
  )
})

const DateInput: React.FC = React.memo(() => {
  const classes = useCommonStyles()
  const { t } = useT()
  const { value, handleChange } = useContext(FormContext)
  const disabled = value.search_time_type === '--'
  const {
    shouldDisableStartDate,
    shouldDisableEndDate
  } = useMemo(() => {
    const fiveYearsAgo = startOfYear(subYears(new Date(), 5))
    return createShouldDisableDate(value.time.start, value.time.end, fiveYearsAgo)
  }, [value.time])
  const tools = useMemo(() => {
    return [
      {
        label: t('common.today'),
        change: setToday,
        disabled
      },
      {
        label: t('common.yesterday'),
        change: setYesterday,
        disabled
      },
      {
        label: t('common.thisMonth'),
        change: setThisMonth,
        disabled
      }
    ]
  }, [t, disabled])
  const startOption = useMemo(() => ({
    label: t('common.beginAt'),
    shouldDisableDate: shouldDisableStartDate,
    disabled
  }), [shouldDisableStartDate, t, disabled])

  const endOption = useMemo(() => ({
    label: t('common.endAt'),
    shouldDisableDate: shouldDisableEndDate,
    disabled
  }), [shouldDisableEndDate, t, disabled])

  const dateClasses = useMemo(() => ({
    button: classes.pinkGradualButton
  }), [classes.pinkGradualButton])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(handleChange('time'), [])

  return (
    <DateInputBase
      value={value.time}
      onChange={onChange}
      start={startOption}
      end={endOption}
      tools={tools}
      classes={dateClasses}
      FrontComponent={SearchDropDown}
      denseToolbar
      denseDatePicker
    />
  )
})

const FinanceRoleInput: React.FC = () => {
  const { t } = useT()
  const { value } = useContext(FormContext)
  const filterStr = useMemo(() => {
    if (value.states === PlayerStates.NOT_DEPOSITABLE) return 'deposit'
    if (value.states === PlayerStates.NOT_WITHDRAWABLE) return 'withdraw'
    return ''
  }, [value])

  const financeRolesForbidOptions = useMemo(() => {
    return Object.keys(financeRoleTypeName)
      .map((key) => key as FinanceRoleType)
      .map((key) => ({ name: t(financeRoleTypeName[key]), value: key }))
      .filter((item) => (item.value.includes(filterStr)))
  }, [t, filterStr])

  if (!filterStr) return null

  return (
    <Grid item xs={12} md={6} lg={3}>
      <FormField<PlayerFormType, MultipleSelectorPropTypes>
        context={FormContext}
        component={MultipleSelector}
        name="finance_roles"
        label={t('common.forbidFunctionSub')}
        options={financeRolesForbidOptions}
        fullWidth
      />
    </Grid>
  )
}

const ForbiddenGameInput: React.FC = () => {
  const { t } = useT()
  const { value } = useContext(FormContext)
  if (value.states !== PlayerStates.NOT_BETABLE) return null

  return (
    <Grid item xs={12} md={6} lg={3}>
      <FormField<PlayerFormType, GameIdProps>
        context={FormContext}
        component={GameIdInput}
        name="forbidden_games"
        label={t('common.forbidFunctionSub')}
        fullWidth
        multiple
      />
    </Grid>
  )
}

const PlayerForm: React.FC = () => {
  const classes = useCommonStyles()
  const { t } = useT()
  const layers = useGDKStore.player.layers()

  const request = useRequestFromSearch({ searchToRequest })

  const defaultForm = useMemo(() => {
    if (request) {
      return initialForm({
        ...(request as unknown as PlayerFormType),
        time: {
          start: request.start_at === undefined ? null : new Date(request.start_at),
          end: request.end_at === undefined ? null : new Date(request.end_at)
        },
        states: request.states === undefined ? '--' : request.states[0]
      })
    }
    return initialForm()
  }, [request])

  const handleSubmit = useChangeUrlSubmit({
    toAddNowTimestamp: true,
    formToRequest,
    encodePath: allRoutes.player.encodePath
  })
  const validation = useMemo(() => {
    return {
      search_time_type: [],
      time: [
        {
          func: (value, form) => {
            if (form.search_time_type !== '--' && (value as DateInputValue).start === null && (value as DateInputValue).end === null) {
              return createErrorResult('time', t('error.mustNotEmpty'))
            }
            return createCorrectResult('time')
          },
          when: ['change', 'beforeClickSubmit']
        },
        {
          func: createValidateStartAtWithEndAt('time', t),
          when: ['change', 'beforeClickSubmit']
        }
      ],
      account: [],
      staff_account: [],
      rank: [],
      layer_id: [],
      status: [],
      states: [],
      wallet_protocol: [],
      pm_version_switch: [],
      finance_roles: [],
      forbidden_games: [],
      fm_warned: [],
      rc_warned: [],
      register_ip: [],
      real_name: [],
      bank_account: [],
      phone: []
    } as FormValidation<PlayerFormType>
  }, [t])

  const rankOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }]
      .concat(
        Object.keys(playerRankName)
          .map((key) => key as PlayerRankType)
          .map((key) => ({ name: t(playerRankName[key]), value: key })) as any
      )
  }, [t])

  const layerOptions = useMemo(() => {
    return [
      { name: t('common.all'), value: '--' }
    ]
      .concat(
        layers.map((item) => ({ name: item.name, value: item.id })) as any
      )
  }, [t, layers])

  const statusOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }]
      .concat(
        Object.keys(playerStatusName)
          .map((key) => Number(key) as PlayerStatusType)
          .map((key) => ({ name: t(playerStatusName[key]), value: key })) as any
      )
  }, [t])

  const bindAccountOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }]
      .concat(
        Object.keys(bindAccount)
          .map((key) => key as BindAccountType)
          .map((key) => ({ name: t(bindAccount[key]), value: key }))
      )
  }, [t])

  const permissionOptions = useMemo(() => {
    return [{ name: t('common.pleaseChoose'), value: '--' }]
      .concat(
        Object.keys(stateTypeName)
          .map((key) => key as PlayerStates)
          .map((key) => ({ name: t(stateTypeName[key]), value: key })) as any
      )
  }, [t])

  const pmVersionOptions = useMemo(() => {
    return [{ name: t('common.all'), value: '--' }]
      .concat(
        Object.keys(pmVersionName)
          .map((key) => key as PMVersionType)
          .map((key) => ({ name: t(pmVersionName[key]), value: key }))
      )
  }, [t])

  return (
    <FormStateProvider
      context={FormContext}
      defaultValue={defaultForm}
      onSubmit={handleSubmit}
      validation={validation}
      getValueFromEvent={getValueFromEvent}
      getChangedForm={getChangedForm}
    >
      <Paper>
        <Box padding={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                paddingY={1.25}
                paddingX={2}
                className={classes.pinkTitleBar}
              >
                <Typography variant="h5">
                  {t('page.player')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <DateInput />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="account"
                    label={t('common.playerAccount')}
                    placeholder={t('placeholder.inputPlayerAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="staff_account"
                    label={t('common.belongStaffAccount')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="rank"
                    label={t('common.playerRank')}
                    options={rankOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="layer_id"
                    label={t('common.playerLayer')}
                    options={layerOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="register_ip"
                    label={t('common.registerIPAddress')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="real_name"
                    label={t('common.peopleName')}
                    placeholder={t('placeholder.inputPeopleName')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="bank_account"
                    label={t('common.bankAccountAndCryptoAddress')}
                    placeholder={t('placeholder.inputBankAccountAndCryptoAddress')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, TextFieldProps>
                    context={FormContext}
                    component={TextField}
                    name="phone"
                    label={t('common.cellphone')}
                    placeholder={t('placeholder.inputPhoneNumber')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="status"
                    label={t('common.playerStatus')}
                    options={statusOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="states"
                    label={t('common.playerPermission')}
                    options={permissionOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="wallet_protocol"
                    label={t('common.bindDebitCard')}
                    options={bindAccountOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, DropDownProps>
                    context={FormContext}
                    component={DropDown}
                    name="pm_version_switch"
                    label={t('common.pmVersion')}
                    options={pmVersionOptions}
                    fullWidth
                  />
                </Grid>
                <FinanceRoleInput />
                <ForbiddenGameInput />
              </Grid>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, OnOffCheckboxProps>
                    context={FormContext}
                    component={OnOffCheckbox}
                    name="fm_warned"
                    label={`${t('common.finance')} ${t('common.warning')}`}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormField<PlayerFormType, OnOffCheckboxProps>
                    context={FormContext}
                    component={OnOffCheckbox}
                    name="rc_warned"
                    label={`${t('common.riskControl')} ${t('common.warning')}`}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <FormSubmitButton
                    component={Button}
                    context={FormContext}
                    type="submit"
                    className={classes.purpleGradualButton}
                  >
                    {t('common.search')}
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </FormStateProvider>
  )
}

export default React.memo(PlayerForm)
