import React, { useMemo, useState, Dispatch, SetStateAction, createContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { searchToRequest } from './PlayerForm'
import { useRequestFromSearch, useGetDataByPayload, usePaginationClickAndChangeUrl } from '../../../utils/default/ComplexFlowHook'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import useT from '../../../i18ns/admin/useT'
import { AdminPlayerListItem, PaginationRes, AccountWarnedType, PermissionType, FinanceRoleType, PlayerStatusType } from '@golden/gdk-admin'
import { createDefaultPaginationData, formatMoney, createTableData } from '../../../utils/default/TableHelper'
import useGDK from '../../../providers/admin/gdk/useGDK'
import DateTime from '../../default/present/DateTime'
import playerRankName from '../../../constants/default/playerRankName'
import playerStatusName from '../../../constants/default/playerStatusName'
import allRoute from '../route/route'
import CoreTable from '../../default/present/CoreTable'
import { MemoPopoverPayload, createDefaultMemoPopoverPayload } from '../../default/memo/MemoPopover'
import MemoTextWithTitleAndContext from '../../default/memo/MemoTextWithTitleAndContext'
import MemoPopoverWithContext from '../../default/memo/MemoPopoverWithContext'
import PlayerIsWarnedIcon from '../PlayerIsWarnedIcon'
import TKeyType from '../../../i18ns/admin/TKeyType'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import financeRoleTypeName from '../../../constants/admin/financeRoleTypeName'
import { mainWalletName } from '../../../utils/default/PlatformHelper'

const useStyles = makeStyles(() => ({
  forbid: {
    color: '#c41f46'
  },
  warnTipGrid: {
    width: '20px'
  },
  accountText: {
    minWidth: '100px',
    maxWidth: '120px'
  }
}))

enum FinancePermission {
  WITHDRAW = 'withdraw',
  DEPOSIT = 'deposit'
}

interface RowType {
  id: number
  account: React.ReactElement
  nickname: string
  staff: string
  rank: string
  layer: string
  status: React.ReactElement
  cash: string
  register: React.ReactElement
  ip: string
  login: React.ReactElement
  forbid: React.ReactElement
  memo: React.ReactElement
  function: React.ReactElement
}

const FinanceMemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const BattleFieldMemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const RiskMemoContext = createContext<[MemoPopoverPayload, Dispatch<SetStateAction<MemoPopoverPayload>>]>([
  createDefaultMemoPopoverPayload(),
  () => {}
])

const DepositWithdrawForbidGrid: React.FC<{ permission: FinancePermission, item: AdminPlayerListItem<Date> }> = (props) => {
  const { permission, item } = props
  const { t } = useT()
  const classes = useStyles()
  const allWithdrawFinanceRolesLength = Object.keys(financeRoleTypeName).map((key) => key as FinanceRoleType).filter((item) => (item.includes('withdraw'))).length
  const filteredForbidMethods = item.finance_roles ? item.finance_roles.filter((el) => (el.includes(permission))) : []
  if (item.not_depositable && permission === FinancePermission.DEPOSIT) return (<Grid item className={classes.forbid}>{t('common.deposit')}</Grid>)
  if ((item.not_withdrawable || item.status === PlayerStatusType.NO_CARD) && permission === FinancePermission.WITHDRAW) return (<Grid item className={classes.forbid}>{t('common.withdraw')}</Grid>)
  if (item.is_first_withdraw_limit && permission === FinancePermission.WITHDRAW) {
    return (
      <Grid item className={classes.forbid}>
        {t(`common.${permission}` as TKeyType)}{filteredForbidMethods.includes(FinanceRoleType.NOT_WITHDRAW_DEBIT_CARD) ? '' : `(${allWithdrawFinanceRolesLength - 1})`}
      </Grid>
    )
  }
  if (filteredForbidMethods.length) {
    return (<Grid item className={classes.forbid}>{t(`common.${permission}` as TKeyType)}{`(${filteredForbidMethods.length})`}</Grid>)
  }
  return null
}

const BetForbidGrid: React.FC<{ item: AdminPlayerListItem<Date> }> = (props) => {
  const { item } = props
  const { t } = useT()
  const classes = useStyles()
  if (item.not_betable) return (<Grid item className={classes.forbid}>{t('common.bet')}</Grid>)
  if (item.forbidden_games?.length) return (<Grid item className={classes.forbid}>{t('common.bet')}{`(${item.forbidden_games.length})`}</Grid>)
  return null
}

const PlayerTable: React.FC = () => {
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const readableDetail = useChecker([
    PermissionType.PLAYER_MANAGEMENT_INFO_READ,
    PermissionType.PLAYER_MANAGEMENT_PERMISSION_READ,
    PermissionType.PLAYER_MANAGEMENT_CENTER_READ,
    PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD_READ,
    PermissionType.PLAYER_MANAGEMENT_CRYPTO_WALLET_READ,
    PermissionType.PLAYER_MANAGEMENT_TRANSACTION_READ,
    PermissionType.PLAYER_MANAGEMENT_WALLET_READ
  ])
  const [list, setList] = useState<PaginationRes<AdminPlayerListItem[]>>(createDefaultPaginationData([]))
  const request = useRequestFromSearch({ searchToRequest })
  const payload = useMemo(() => {
    return request ?? null
  }, [request])
  useGetDataByPayload({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    payload: payload!,
    gdkFunc: (payload) => gdk.player.getPlayerList(payload),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: PaginationRes<AdminPlayerListItem[]>) => {
      setList(res)
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError,
    canLoadData: request !== undefined
  })
  const tableClasses = useMemo(() => ({
    head: commonClasses.pinkTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead,
    cellBody: commonClasses.pre
  }), [commonClasses])
  const rows: RowType[] = useMemo(() => {
    return list.data.map((item) => {
      const isWarned = (item.rc_warned || item.fm_warned)
      return {
        id: item.id,
        account: (
          <Grid container direction="row" wrap="nowrap" alignItems="center" justify="center">
            {isWarned && (
              <Grid container item className={classes.warnTipGrid} direction="column" wrap="nowrap" alignItems="center" justify="center">
                <PlayerIsWarnedIcon isWarned={item.fm_warned} />
                <PlayerIsWarnedIcon isWarned={item.rc_warned} warnedType={AccountWarnedType.RISK_CONTROL} />
              </Grid>
            )}
            <Grid item className={classes.accountText}>{item.account}</Grid>
          </Grid>
        ),
        nickname: item.nickname,
        staff: item.staff,
        rank: t(playerRankName[item.rank]),
        layer: item.layer_name ?? t('common.none'),
        status: (<>{t(playerStatusName[item.status])}</>),
        cash: formatMoney(item.cash),
        register: (<DateTime time={item.created_at} />),
        ip: item.register_ip,
        login: item.last_login_at ? (<DateTime time={item.last_login_at} />) : (<p></p>),
        forbid: (
          <Grid container direction="row" wrap="wrap" alignItems="center" justify="center" spacing={1}>
            {item.not_loginable && (<Grid item className={classes.forbid}>{t('common.login')}</Grid>)}
            <BetForbidGrid item={item} />
            <DepositWithdrawForbidGrid permission={FinancePermission.DEPOSIT} item={item} />
            <DepositWithdrawForbidGrid permission={FinancePermission.WITHDRAW} item={item} />
            {item.not_transferable && (<Grid item className={classes.forbid}>{t('common.transfer')}</Grid>)}
            {item.not_activity && (<Grid item className={classes.forbid}>{t('common.activity')}</Grid>)}
            {item.not_sms_verify_sendable && (<Grid item className={classes.forbid}>{t('common.sms')}</Grid>)}
          </Grid>
        ),
        memo: (
          <React.Fragment>
            {item.finance_memo !== '' && (<MemoTextWithTitleAndContext context={FinanceMemoContext} title={t('common.finance')} memo={item.finance_memo} />)}
            {item.battle_field_memo !== '' && (<MemoTextWithTitleAndContext context={BattleFieldMemoContext} title={t('common.customService')} memo={item.battle_field_memo} />)}
            {item.risk_memo !== '' && (<MemoTextWithTitleAndContext context={BattleFieldMemoContext} title={t('common.riskControl')} memo={item.risk_memo} />)}
          </React.Fragment>
        ),
        function: (
          <Button
            className={commonClasses.blueGradualOutlineButton}
            component={Link}
            to={allRoute.playerDetail.encodePath({ search: {}, param: { id: item.id } })}
          >
            {t('common.detailSetting')}
          </Button>
        )
      } as RowType
    })
  }, [list.data, t, classes, commonClasses])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        account: {
          label: t('common.playerAccount'),
          value: 'account',
          align: 'center'
        },
        nickname: {
          label: t('common.playerNickname'),
          value: 'nickname',
          align: 'center'
        },
        staff: {
          label: t('common.belongStaffAccount'),
          value: 'staff',
          align: 'center'
        },
        rank: {
          label: t('common.playerRank'),
          value: 'rank',
          align: 'center'
        },
        layer: {
          label: t('common.playerLayer'),
          value: 'layer',
          align: 'center'
        },
        status: {
          label: t('common.playerStatus'),
          value: 'status',
          align: 'center'
        },
        cash: {
          label: mainWalletName,
          value: 'cash',
          align: 'right'
        },
        register: {
          label: t('common.registeredAt'),
          value: 'register',
          align: 'center'
        },
        ip: {
          label: t('common.registerIP'),
          value: 'ip',
          align: 'center'
        },
        login: {
          label: t('common.lastLoginAt'),
          value: 'login',
          align: 'center'
        },
        forbid: {
          label: t('common.forbidFunction'),
          value: 'forbid',
          align: 'center'
        },
        memo: {
          label: t('common.memo'),
          value: 'memo',
          align: 'center'
        },
        function: {
          label: t('common.operation'),
          value: 'function',
          align: 'center'
        }
      },
      [
        'account',
        'nickname',
        'staff',
        'rank',
        'layer',
        'status',
        'cash',
        'register',
        'ip',
        'login',
        'forbid',
        'memo',
        ...(readableDetail ? ['function'] as const : [])
      ],
      rows,
      'id'
    )
  }, [readableDetail, rows, t])
  const handlePagination = usePaginationClickAndChangeUrl({
    request,
    encodePath: allRoute.player.encodePath
  })
  if (request === undefined) return null
  return (
    <MemoPopoverWithContext memoPopoverContext={FinanceMemoContext}>
      <MemoPopoverWithContext memoPopoverContext={BattleFieldMemoContext}>
        <MemoPopoverWithContext memoPopoverContext={RiskMemoContext}>
          <ScrollablePaper marginX={5}>
            <Box padding={4}>
              <LoadingAndErrorFrame { ...pageFlow.status }>
                <CoreTable
                  classes={tableClasses}
                  data={data}
                  total={list.total}
                  showPagination
                  page={request.page}
                  onChangePage={handlePagination}
                />
              </LoadingAndErrorFrame>
            </Box>
          </ScrollablePaper>
        </MemoPopoverWithContext>
      </MemoPopoverWithContext>
    </MemoPopoverWithContext>
  )
}

export default React.memo(PlayerTable)
