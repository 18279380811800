import React, { useState, useMemo, useCallback, useContext, Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useT from '../../../i18ns/admin/useT'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import LoadingAndErrorFrame from '../../default/frames/LoadingAndErrorFrame'
import { useGetDataByPayload, useReload } from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { AdminPlayerDebitCard, AdminPlayerDebitCards, PermissionType } from '@golden/gdk-admin'
import DateTime from '../../default/present/DateTime'
import { createTableData } from '../../../utils/default/TableHelper'
import CoreTable from '../../default/present/CoreTable'
import ScrollablePaper from '../../default/present/ScrollablePaper'
import BlacklistIcon from '../../../assets/admin/images/cardnotallowed.png'
import { useChecker } from '../../../utils/admin/AdminRouteHook'
import DebitCardUpdateButton from './DebitCardUpdateButton'
import DebitCardBlockButton from './DebitCardBlockButton'
import DebitCardDeleteButton from './DebitCardDeleteButton'
import DebitCardCreateButton from './DebitCardCreateButton'
import TableTabs from '../TableTabs'
import BoundRelateRecordButton from './BoundRelateRecordButton'

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}))

interface PropTypes {
  id: number
  section: string
  reload: () => void
  loaded: Dispatch<SetStateAction<string>>
}

type CardsTab = 'binding' | 'bound'

interface RowType {
  id: number
  symbol: React.ReactElement
  time: React.ReactElement
  name: string
  account: React.ReactElement
  bank: string
  function: React.ReactElement
}

const Buttons: React.FC<{
  index: number
  id: number
  card: AdminPlayerDebitCard
  isTabBinding: boolean
  reload: () => void
}> = React.memo((props) => {
  const { index, id, card, reload, isTabBinding } = props
  return (
    <Grid container direction="row" spacing={1} justify="center">
      {isTabBinding && (
        <React.Fragment>
          <Grid item>
            <DebitCardUpdateButton
              index={index}
              id={id}
              card={card}
              reload={reload}
            />
          </Grid>
          <Grid item>
            <DebitCardDeleteButton index={index} id={id} card={card} reload={reload} />
          </Grid>
          <Grid item>
            <DebitCardBlockButton id={id} cardId={card.id} isBlock={card.is_blacklist} reload={reload} account={card.bank_account} />
          </Grid>
        </React.Fragment>
      )}
      <Grid item>
        <BoundRelateRecordButton relatedRecords={card.related_records} />
      </Grid>
    </Grid>
  )
})

const PlayerDetailCardSection: React.FC<PropTypes> = (props) => {
  const { id, section, reload: reloadProps, loaded } = props
  const { reload, reloadFlag } = useReload()
  const [cards, setCards] = useState<AdminPlayerDebitCards>({ binding: [], bound: [] })
  const [tab, setTab] = useState<CardsTab>('binding')
  const writableEdit = useChecker([PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD])
  const writableBind = useChecker([PermissionType.PLAYER_MANAGEMENT_DEBIT_CARD_BIND])
  const { t } = useT()
  const classes = useStyles()
  const gdk = useGDK()
  const commonClasses = useCommonStyles()
  const tableClasses = useMemo(() => ({
    head: commonClasses.greyTableHead,
    row: commonClasses.tableRow,
    cellHead: commonClasses.tableCellHead
  }), [commonClasses])
  const pageFlow = usePageFlow()

  const tabs = useMemo(() => [
    { label: t('common.currentBinding'), value: 'binding' },
    { label: t('common.pastBound'), value: 'bound' }
  ], [t])
  const isTabBinding = useMemo(() => (tab === 'binding'), [tab])

  useGetDataByPayload({
    payload: id,
    gdkFunc: (payload) => gdk.player.getPlayerDebitCards(payload),
    gdkFuncDependencies: [gdk, reloadFlag],
    onBeforeFetch: pageFlow.setLoadingStart,
    onSuccess: (res: AdminPlayerDebitCards) => {
      setCards(res)
      pageFlow.setContentShow()
      loaded(section)
    },
    onError: pageFlow.setGDKError
  })
  const reloadAll = useCallback(() => {
    reload()
    reloadProps()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const cardList = useMemo(() => {
    if (isTabBinding) return cards.binding
    return cards.bound
  }, [cards, tab])
  const isMobileRealnameMatch = cards?.binding.length ? cards.binding[0].is_mobile_realname_match : true
  const rows = useMemo(() => {
    return cardList.map((item, index) => {
      return {
        id: item.id,
        symbol: (
          <Grid container direction="column" justify="center" spacing={2}>
            {index === 0 && (
              <Grid item>
                <span className={clsx(commonClasses.chipText, classes.chip)} style={{ backgroundColor: '#f89f0b' }}>1st</span>
              </Grid>
            )}
          </Grid>
        ),
        time: (<DateTime time={isTabBinding ? item.created_at : item.deleted_at} />),
        name: item.real_name,
        account: (
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            {item.is_blacklist && (
              <img alt="X" src={BlacklistIcon} className={classes.chip} />
            )}
            {item.bank_account}
          </Box>
        ),
        bank: item.bank_name,
        function: (
          <Buttons index={index} id={id} card={item} reload={reloadAll} isTabBinding={isTabBinding}/>
        )
      } as RowType
    })
  }, [cardList, commonClasses, classes, id, reloadAll, tab])
  const data = useMemo(() => {
    return createTableData<RowType>(
      {
        id: {
          label: '',
          value: 'id'
        },
        symbol: {
          label: '',
          value: 'symbol',
          align: 'center'
        },
        time: {
          label: isTabBinding ? t('common.bindData') : t('common.unbindData'),
          value: 'time',
          align: 'center'
        },
        name: {
          label: t('common.realName'),
          value: 'name',
          align: 'center'
        },
        account: {
          label: t('common.bankAccount'),
          value: 'account',
          align: 'center'
        },
        bank: {
          label: t('common.bank'),
          value: 'bank',
          align: 'center'
        },
        function: {
          label: t('common.function'),
          value: 'function',
          align: 'center',
          width: 300
        }
      },
      writableEdit
        ? [
            'symbol',
            'time',
            'name',
            'account',
            'bank',
            'function'
          ]
        : [
            'symbol',
            'time',
            'name',
            'account',
            'bank'
          ],
      rows,
      'id'
    )
  }, [rows, t, writableEdit, tab])
  return (
    <div id={section}>
      <ScrollablePaper marginX={5}>
        <Box padding={4}>
          <Box
            paddingY={1.25}
            paddingX={2}
            className={commonClasses.pinkTitleBar}
          >
            <Typography variant="h5">
              {t('common.debitCardData')}
            </Typography>
          </Box>
          <Box>
            <Box display="flex" paddingTop={2}>
              <TableTabs
                value={tab}
                tabs={tabs}
                onChange={(value) => {
                  setTab(value as CardsTab)
                }}
              />
            </Box>
          </Box>
          <Box paddingY={2}>
            <LoadingAndErrorFrame { ...pageFlow.status }>
              <CoreTable
                classes={tableClasses}
                data={data}
                total={cardList.length}
              />
            </LoadingAndErrorFrame>
          </Box>
          {!isMobileRealnameMatch && <Box whiteSpace="pre" marginBottom={2}>
            <Typography color="secondary">
              {t('common.playerCardRealnameNotMatch')}
            </Typography>
          </Box>}
          <Box whiteSpace="pre">
            <Typography style={{ color: '#808080' }}>
              {t('common.playerCardTip')}
            </Typography>
          </Box>
          {writableBind && (<DebitCardCreateButton id={id} reload={reloadAll} />)}
        </Box>
      </ScrollablePaper>
    </div>
  )
}

export default React.memo(PlayerDetailCardSection)
