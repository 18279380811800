import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import EditAnnouncementForm, {
  EditAnnouncementFormType,
  initialForm
} from '../../../components/admin/forestage/EditAnnouncementForm'
import LoadingAndErrorFrame from '../../../components/default/frames/LoadingAndErrorFrame'
import {
  useRedirectHandleBack,
  useGetDataByParams,
  useRequestFromSearch
} from '../../../utils/default/ComplexFlowHook'
import useGDK from '../../../providers/admin/gdk/useGDK'
import { usePageFlow } from '../../../utils/default/PageFlowHook'
import { useCommonStyles } from '../../../utils/admin/StyleHook'
import allRoute, { Path } from '../../../components/admin/route/route'
import useT from '../../../i18ns/admin/useT'
import { searchToRequest } from './AnnouncementPage'
import { defaultEditorState, parseContent } from '@golden/tiptap-react'

const AnnouncementLookPage: React.FC = () => {
  const request = useRequestFromSearch({ searchToRequest })
  const classes = useCommonStyles()
  const { t } = useT()
  const gdk = useGDK()
  const pageFlow = usePageFlow(true, false)
  const [defaultForm, setDefaultForm] = useState<EditAnnouncementFormType>(initialForm())
  useGetDataByParams({
    path: Path.FORESTAGE_ANNOUNCEMENT_LOOK,
    gdkFunc: (param: { id: string }) => gdk.announcement.getAnnouncement(Number(param.id)),
    gdkFuncDependencies: [gdk],
    onBeforeFetch: pageFlow.setLoadingStart,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async (entry) => {
      setDefaultForm({
        title: entry.title,
        beginAt: entry.begin_at,
        endAt: entry.end_at,
        content: {
          ...defaultEditorState,
          content: await parseContent(entry.content)
        },
        isPopup: entry.is_pop_up,
        isPinned: entry.is_pinned,
        isMarquee: entry.is_marquee,
        type: entry.type,
        viewers: entry.viewers.map((item) => ({ role: item.role_type, value: item.sub_type }))
      })
      pageFlow.setContentShow()
    },
    onError: pageFlow.setGDKError
  })

  const [_, handleDebouncedBack] = useRedirectHandleBack({ path: allRoute.forestageAnnouncement.encodePath({ search: { ...request }, param: {} }) })

  return (
    <React.Fragment>
      <Box paddingY={5} paddingX={4}>
        <LoadingAndErrorFrame {...pageFlow.status}>
          <Paper>
            <Box padding={4}>
              <Typography className={classes.goldenWord} variant="h5">{t('page.lookAnnouncement')}</Typography>
              <Box paddingY={4}>
                <EditAnnouncementForm
                  defaultValue={defaultForm}
                  onSubmit={(form) => form}
                  okText={''}
                  onBack={handleDebouncedBack}
                  isLook
                />
              </Box>
            </Box>
          </Paper>
        </LoadingAndErrorFrame>
      </Box>
    </React.Fragment>
  )
}

export default React.memo(AnnouncementLookPage)
