import React, { useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useCommonStyles, useDialogStyles } from '../../../utils/admin/StyleHook'
import useT from '../../../i18ns/admin/useT'
import { GameType } from '@golden/gdk-admin'
import useGlobalDialog from '../../../providers/admin/dialog/useGlobalDialog'
import { createGlobalDialogConfig } from '../../../utils/default/DialogHelper'
import { useDialogFlow } from '../../../utils/default/DialogHook'
import { SearchToRequestFunc, useRequestFromSearch } from '../../../utils/default/ComplexFlowHook'
import GameIdInput from '../GameIdInput'
import { GameFactory } from '@golden/game-china'
interface PropTypes {
  title: string
  onChange: (gameId: number) => void
  warning?: boolean
}

export const searchToRequest: SearchToRequestFunc<{ gameId: number, page: number }> = (search) => {
  if (search.gameId) {
    return { gameId: Number(search.gameId), page: search.page ? Number(search.page) : 1 }
  }
  return { gameId: GameFactory.getFirstId(), page: 1 }
}

const ExternalGameChannelForm: React.FC<PropTypes> = (props) => {
  const { title, onChange, warning } = props
  const globalDialog = useGlobalDialog()
  const classes = useCommonStyles()
  const dialogClasses = useDialogStyles()
  const [tempGame, setTempGame] = useState<number>(0)
  const { t } = useT()

  const request = useRequestFromSearch({ searchToRequest })

  useDialogFlow(globalDialog, 'changeList', () => {
    if (tempGame) {
      onChange(tempGame)
    }
  }, [tempGame, onChange])

  return (
    <Box padding={4}>
      <Box
        paddingY={1.25}
        paddingX={2}
        className={classes.pinkTitleBar}
      >
        <Typography variant="h5">
          {title}
        </Typography>
      </Box>
      <Box paddingTop={2}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={3}>
            <GameIdInput
              fullWidth
              noAll
              value={request?.gameId}
              onChange={useCallback((value) => {
                if (warning) {
                  globalDialog.setConfig(createGlobalDialogConfig({
                    variant: 'info',
                    showIcon: false,
                    notUseTypo: true,
                    message: (
                      <React.Fragment>
                        <div className={dialogClasses.title}>
                          {t('dialog.changeChannelList')}
                        </div>
                        <div className={dialogClasses.text}>
                          {t('dialog.unsaveLost')}
                        </div>
                      </React.Fragment>
                    )
                  }))
                  globalDialog.setOpen({
                    id: 'changeList',
                    isOK: false,
                    value: true
                  })
                  setTempGame(value)
                  return false
                } else {
                  onChange(value)
                }
              // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [warning, t, dialogClasses, onChange])}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default React.memo(ExternalGameChannelForm)
